import { KVDbBoolean, KVDbNumber, KVDbString } from "./KVDbTypes";

export function BackgroundAnimationEntry() {
  return new KVDbBoolean("BgAnimation");
}

export function ExperienceEntry() {
  return new KVDbNumber("Exp");
}

export function LimitedRandomnessEntry() {
  return new KVDbBoolean("LimitRand");
}

/* export function FriendsEntry() {
  return new KVDbNumber("friends");
} */

export function MovesEntry() {
  return new KVDbNumber("Moves");
}

export function MusicEntry() {
  return new KVDbBoolean("Music");
}

export function PlayerNameEntry() {
  return new KVDbString("Name");
}

export function PointsEntry() {
  return new KVDbNumber("Points");
}

export function RoundsEntry() {
  return new KVDbNumber("Rounds");
}

export function SharedUrlsEntry() {
  return new KVDbString("Shared");
}

export function StarsEntry() {
  return new KVDbNumber("Stars");
}

export function StarsGainedEntry() {
  return new KVDbNumber("StarsGained");
}

export function StatsEntry(statsKey: string) {
  return new KVDbNumber(statsKey);
}

export function ThemeEntry() {
  return new KVDbString("Theme");
}
