import { ListItemText, ListSubheader, Menu, MenuItem } from "@material-ui/core";
import React, { Component } from "react";
import LocalizedStrings from "react-localization";
import { sprintf } from "sprintf-js";
import KVDb from "../../db/KVDb";
import { StarsEntry } from "../../db/KVDbEntries";
import str from "../../StringResources";

const localStr = new LocalizedStrings({
  en: {
    experiencePoints: "experience points",
    formatForStars: "for %d⭐",
  },
  pl: {
    experiencePoints: "punktów doświadczenia",
    formatForStars: "za %d⭐",
  },
});

interface Props {
  anchor: HTMLElement | null;
  stars: number;
  onClose: () => void;
  onStarsChanged: (stars: number) => void;
  onExperienceChanged: (experience: number) => void;
}

interface State {
  stars: number;
}

type Offer = {
  stars: number;
  experience: number;
};

export default class BuyExperienceMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      stars: this.props.stars,
    };
  }

  render() {
    const offers: Offer[] = [
      { stars: 10, experience: 5 },
      { stars: 25, experience: 15 },
      { stars: 50, experience: 35 },
      { stars: 100, experience: 75 },
    ];

    return (
      <div>
        <Menu
          anchorEl={this.props.anchor}
          open={this.props.anchor !== null}
          onEnter={() => {
            this.loadStars();
          }}
          onClose={() => this.props.onClose()}
        >
          <ListSubheader>{str.buy}</ListSubheader>
          {offers.map((offer) =>
            this.createOfferItem(offer.stars, offer.experience)
          )}
        </Menu>
      </div>
    );
  }

  private createOfferItem(starsRequired: number, experience: number) {
    return (
      <MenuItem
        key={experience}
        disabled={!this.isAvailable(starsRequired)}
        onClick={
          !this.isAvailable(starsRequired)
            ? undefined
            : () => {
                this.buyExperience(starsRequired, experience);
              }
        }
      >
        <ListItemText
          primary={`${experience} ${localStr.experiencePoints}`}
          secondary={sprintf(localStr.formatForStars, starsRequired)}
        ></ListItemText>
      </MenuItem>
    );
  }

  private buyExperience(stars: number, experience: number) {
    this.setState({ stars: this.state.stars - stars });
    this.props.onStarsChanged(-stars);
    this.props.onExperienceChanged(experience);
  }

  private loadStars() {
    KVDb.open()
      .then((db) => db.getOrDefault(StarsEntry(), 0))
      .then((stars) => this.setState({ stars: stars }));
  }

  private isAvailable(starsRequired: number) {
    return this.state.stars >= starsRequired;
  }
}
