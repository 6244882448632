export function finishComponent(props: any) {
  (props as any).history.goBack();
}

export function mapRange(
  value: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number
) {
  const slope = (outMax - outMin) / (inMax - inMin);
  return outMin + slope * (value - inMin);
}

export function trimToRange(n: number, from: number, to: number) {
  return Math.min(to, Math.max(from, n));
}

export function log(x: number, base: number) {
  return Math.log(x) / Math.log(base);
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

export function getRandomElement<Type>(array: Type[]): Type {
  return array[getRandomInt(0, array.length)];
}

export function addVectorsUnsafe(v0: number[], v1: number[]) {
  const res: number[] = [];
  v0.forEach((value, i) => {
    res.push(value + v1[i]);
  });
  return res;
}

export function ifInfinityThen(n: number, ifInfinity: number) {
  return isFinite(n) ? n : ifInfinity;
}

export function vectorsEqualUnsafe(v0: number[], v1: number[]): boolean {
  for (let i = 0; i < v0.length; ++i) {
    if (v0[i] !== v1[i]) return false;
  }
  return true;
}

export function cssStringVar(name: string, value?: string) {
  name = "--" + name;
  if (value) {
    document.documentElement.style.setProperty(name, value);
  }
  return getComputedStyle(document.documentElement).getPropertyValue(name);
}

/**
 *
 * @param name
 * @param value
 * @returns time in milliseconds
 */
export function cssTimeVar(name: string, value?: number) {
  const val = cssStringVar(name, value?.toString());
  if (val.endsWith("ms")) return parseFloat(val.substring(0, val.length - 2));
  else if (val.endsWith("s"))
    return parseFloat(val.substring(0, val.length - 1)) * 1000.0;
  else return 0.0;
}

export function truncateString(str: string, length: number) {
  return str.length <= length ? str : str.substr(0, length);
}

export function isAdBlockerActive() {
  return document.getElementById("ad-blocker-detected") === null;
}
