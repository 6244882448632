import {
  AppBar,
  Box,
  Button,
  IconButton,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import StarIcon from "@material-ui/icons/Star";
import UndoIcon from "@material-ui/icons/Undo";
import React, { Component, RefObject } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import { PLAYER_NAME_LENGTH_LIMIT } from "../Config";
import KVDb from "../db/KVDb";
import {
  BackgroundAnimationEntry,
  ExperienceEntry,
  MusicEntry,
  PlayerNameEntry,
  StarsEntry,
  StarsGainedEntry,
  ThemeEntry,
} from "../db/KVDbEntries";
import Game from "../game/Game";
import Player from "../game/Player";
import Platform from "../Platform";
import {
  ROUTE_GAME,
  ROUTE_GAME_MODES,
  ROUTE_SETTINGS,
  ROUTE_STATS,
} from "../Routes";
import str from "../StringResources";
import { AppTheme, AppThemes, STAR_COLOR } from "../theme/Theme";
import { finishComponent, ifInfinityThen, truncateString } from "../Utils";
import RouterOutlet from "./core/RouterOutlet";
import IntroductionDialog from "./dialogs/IntroductionDialog";
import LeaveGameConfirmationDialog from "./dialogs/LeaveGameConfirmationDialog";
import LevelUpDialog from "./dialogs/LevelUpDialog";
import PlayerCardDialog from "./dialogs/PlayerCardDialog";
import StarsGainedDialog from "./dialogs/StarsGainedDialog";
import GameComponent from "./GameComponent";
import GameModesComponent from "./GameModesComponent";
import MainMenuComponent from "./MainMenuComponent";
import SettingsComponent from "./SettingsComponent";
import StatsComponent from "./StatsComponent";
import "./styles/UiContainerComponent.css";

const STARS_NUMBER_COLOR_INC = "#4CAF50";
const STARS_NUMBER_COLOR_DEC = "#EF5350";

const APP_GRADIENT_LIGHT =
  "linear-gradient(135deg,#ff8f00,#fdd835,#8bc34a,#4db6ac,#1976d2,#3d5afe,#8e24aa,#e53935)";

const APP_GRADIENT_DARK =
  "linear-gradient(135deg,#101010,#6D4C41,#827717,#1b5e20,#006064,#1a237e,#4a148c,#880e4f)";

const STARS_TICKS = 10;

interface Props extends RouteComponentProps {}

interface State {
  theme: AppTheme;
  isBackgroundAnimationEnabled: boolean;
  playerName: string;
  musicOn: boolean;
  introductionDialogOpen: boolean;
  playerCardDialogOpen: boolean;
  levelUpDialogOpen: boolean;
  leaveGameConfirmationDialogOpen: boolean;
  gainedStars: number;
  experience: number;
  stars: number;
  starsNumberColor: string;
  game?: Game;
}

export default class UiContainerComponent extends Component<Props, State> {
  switchNodeRef: RefObject<any>;
  audioRef: RefObject<HTMLAudioElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      theme: AppThemes.FUCHSIA_LIGHT,
      isBackgroundAnimationEnabled: true,
      playerName: "",
      musicOn: false,
      introductionDialogOpen: false,
      playerCardDialogOpen: false,
      levelUpDialogOpen: false,
      leaveGameConfirmationDialogOpen: false,
      gainedStars: 0,
      experience: 0,
      stars: 0,
      starsNumberColor: this.getTextPrimaryColor(AppThemes.FUCHSIA_LIGHT),
      game: undefined,
    };

    this.switchNodeRef = React.createRef(); // TODO when they fix it
    this.audioRef = React.createRef();
  }

  componentDidMount() {
    KVDb.open()
      .then(async (db) => {
        const themeId = (await db.get(ThemeEntry())) || "";
        this.changeTheme(AppThemes.byId(themeId));

        const exp = (await db.get(ExperienceEntry())) || 0;
        const stars = (await db.get(StarsEntry())) || 0;
        const name = (await db.get(PlayerNameEntry())) || "";

        const isBackgroundAnimationEnabled = await db.getOrDefault(
          BackgroundAnimationEntry(),
          true
        );

        this.setState({
          isBackgroundAnimationEnabled: isBackgroundAnimationEnabled,
          playerName: truncateString(name, PLAYER_NAME_LENGTH_LIMIT),
          introductionDialogOpen: name === "",
          experience: ifInfinityThen(exp, 0),
          stars: ifInfinityThen(stars, 0),
        });

        const isMusicOn = await db.getOrDefault(MusicEntry(), true);

        this.setMusicOn(isMusicOn);
      })
      .finally(() => KVDb.close());

    window.addEventListener("beforeunload", (e) => {
      this.pauseMusic();
    });
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        if (this.state.musicOn) {
          this.playMusic();
        }
      } else {
        this.pauseMusic();
      }
    });
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return newProps.location !== this.props.location || newState !== this.state;
  }

  render() {
    return (
      <ThemeProvider theme={this.state.theme.get(false)}>
        <div
          id="app"
          className={Platform.id}
          style={{
            backgroundImage: this.state.theme.isDark
              ? APP_GRADIENT_DARK
              : APP_GRADIENT_LIGHT,
          }}
        >
          <div
            id="app-background-overlay"
            className={
              `${this.state.theme.isDark ? "dark " : ""}` +
              `${
                this.state.isBackgroundAnimationEnabled &&
                this.state.game === undefined
                  ? ""
                  : "visible"
              }`
            }
          >
            <div id="app-centered-container">
              <AppBar color="default" id="app-bar">
                <Toolbar>
                  {!this.hasBackButton() ? null : (
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => this.onBackButtonClick()}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  )}
                  <Typography id="toolbar-title" variant="h6">
                    {this.getToolbarTitle()}
                  </Typography>
                  <div className="grow" />
                  <StarIcon htmlColor={STAR_COLOR} />
                  <Typography style={{ color: this.state.starsNumberColor }}>
                    {this.state.stars <= 999 ? this.state.stars : "999+"}
                  </Typography>
                  <Box mx={1} />
                  {this.state.game === undefined ? (
                    <Button
                      color="inherit"
                      onClick={() =>
                        this.setState({ playerCardDialogOpen: true })
                      }
                      endIcon={<AccountCircleIcon />}
                    >
                      {str.level} {Player.getLevel(this.state.experience)}
                      <div
                        id="level-progress-bar-container"
                        style={{
                          backgroundColor: this.getTextSecondaryColor(),
                        }}
                      >
                        <div
                          id="level-progress-bar"
                          style={{
                            width: `${
                              Player.getProgress(this.state.experience) * 100
                            }%`,
                          }}
                        ></div>
                      </div>
                    </Button>
                  ) : (
                    <Button
                      id="undo-button"
                      startIcon={<UndoIcon />}
                      variant="outlined"
                      color="primary"
                      onClick={() => this.state.game!.undo()}
                    >
                      {str.undo} (<span id="undo-left">0</span>)
                    </Button>
                  )}
                </Toolbar>
              </AppBar>
              <RouterOutlet location={this.props.location}>
                <Switch location={this.props.location}>
                  <Route exact path={`/`}>
                    <div className="content">
                      <MainMenuComponent
                        playerName={this.state.playerName}
                        onStarsChanged={(stars) => this.addStars(stars, false)}
                      />
                    </div>
                  </Route>
                  <Route exact path={`/${ROUTE_GAME_MODES}`}>
                    <div className="content">
                      <GameModesComponent
                        experience={this.state.experience}
                        onGameConfigChange={(config) =>
                          this.setState({ game: new Game(config) })
                        }
                      />
                    </div>
                  </Route>
                  <Route exact path={`/${ROUTE_GAME}`}>
                    <div className="content">
                      <GameComponent
                        history={this.props.history}
                        location={this.props.location}
                        match={this.props.match}
                        game={this.state.game!}
                        onStarsAdded={(stars) =>
                          this.setState({ gainedStars: stars })
                        }
                        onStarsTaken={(stars) => this.addStars(-stars, false)}
                        onExperienceAdded={(exp) => this.addExp(exp)}
                        onUnmount={() => this.setState({ game: undefined })}
                      />
                    </div>
                  </Route>
                  <Route exact path={`/${ROUTE_SETTINGS}`}>
                    <div className="content">
                      <SettingsComponent
                        history={this.props.history}
                        location={this.props.location}
                        match={this.props.match}
                        playerName={this.state.playerName}
                        theme={this.state.theme}
                        musicOn={this.state.musicOn}
                        isBackgroundAnimationEnabled={
                          this.state.isBackgroundAnimationEnabled
                        }
                        onNameChange={(name) =>
                          this.setState({ playerName: name })
                        }
                        onThemeChange={(theme) => {
                          this.changeTheme(theme);
                        }}
                        onBackgroundAnimationSwitch={(on) => {
                          this.setState({ isBackgroundAnimationEnabled: on });
                        }}
                        onMusicSwitch={(on) => this.setMusicOn(on)}
                      />
                    </div>
                  </Route>
                  <Route exact path={`/${ROUTE_STATS}`}>
                    <div className="content">
                      <StatsComponent />
                    </div>
                  </Route>
                </Switch>
              </RouterOutlet>
              <IntroductionDialog
                open={this.state.introductionDialogOpen}
                onClose={() => this.onPlayerNameDialogClose()}
                onNameChanged={(name) => {
                  this.setState({ playerName: name });
                }}
                onStarsChanged={(stars) => this.addStars(stars, false)}
              />
              <PlayerCardDialog
                open={this.state.playerCardDialogOpen}
                playerName={this.state.playerName}
                experience={this.state.experience}
                stars={this.state.stars}
                onClose={() => this.setState({ playerCardDialogOpen: false })}
                onStarsChanged={(stars) => this.addStars(stars, false)}
                onExperienceChanged={(experience) => this.addExp(experience)}
              />
              <StarsGainedDialog
                open={this.state.gainedStars > 0}
                starsGained={this.state.gainedStars}
                onClose={() => this.setState({ gainedStars: 0 })}
                onStarsChanged={(stars) => this.addStars(stars, true)}
              />
              <LevelUpDialog
                open={this.state.levelUpDialogOpen}
                level={Player.getLevel(this.state.experience)}
                onClose={() => this.setState({ levelUpDialogOpen: false })}
              />
              <LeaveGameConfirmationDialog
                open={this.state.leaveGameConfirmationDialogOpen}
                onResult={(confirmed) => {
                  this.setState({ leaveGameConfirmationDialogOpen: false });
                  if (confirmed) {
                    finishComponent(this.props);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <audio loop ref={this.audioRef}>
          {!this.state.musicOn ? null : (
            <source src="/music/lounge.mp3" type="audio/mpeg" />
          )}
        </audio>
      </ThemeProvider>
    );
  }

  private getRoute() {
    return this.props.location.pathname.substring(1);
  }

  private hasBackButton() {
    return this.getRoute() !== "";
  }

  private onBackButtonClick() {
    if (this.getRoute() === ROUTE_GAME) {
      this.setState({ leaveGameConfirmationDialogOpen: true });
    } else {
      finishComponent(this.props);
    }
  }

  private getToolbarTitle() {
    switch (this.getRoute()) {
      case "":
        return str.appName;
      case ROUTE_GAME_MODES:
        return str.gameModes;
      case ROUTE_GAME: {
        const gameMode = this.state.game?.config.gameMode;
        if (gameMode === undefined) {
          return "";
        } else {
          return gameMode.getName();
        }
      }
      case ROUTE_SETTINGS:
        return str.settings;
      case ROUTE_STATS:
        return str.stats;
      default:
        return this.getRoute();
    }
  }

  private onPlayerNameDialogClose() {
    KVDb.open()
      .then((db) => {
        db.set(PlayerNameEntry(), this.state.playerName);
      })
      .finally(() => KVDb.close());
    this.setState({ introductionDialogOpen: false });
  }

  private addStars(stars: number, isGained: boolean) {
    if (stars === 0) {
      return;
    }

    KVDb.open()
      .then((db) => {
        db.add(StarsEntry(), stars);
        if (isGained) {
          db.add(StarsGainedEntry(), stars);
        }
      })
      .finally(() => KVDb.close());

    const absStars = Math.abs(stars);
    const signStars = Math.sign(stars);
    const step =
      (absStars > STARS_TICKS ? Math.floor(absStars / STARS_TICKS) : 1) *
      signStars;
    const numSteps = Math.min(absStars, STARS_TICKS);
    const rest = stars - step * numSteps;

    this.setState({
      starsNumberColor:
        stars > 0 ? STARS_NUMBER_COLOR_INC : STARS_NUMBER_COLOR_DEC,
    });

    for (let i = 0; i < numSteps; ++i) {
      setTimeout(() => {
        this.setState({
          stars: this.state.stars + step,
        });
      }, i * (1000 / numSteps));
    }

    setTimeout(() => {
      this.setState({
        stars: this.state.stars + rest,
        starsNumberColor: this.getTextPrimaryColor(),
      });
    }, 1250);
  }

  private addExp(exp: number) {
    if (exp === 0) {
      return;
    }

    KVDb.open()
      .then((db) => db.add(ExperienceEntry(), exp))
      .finally(() => KVDb.close());

    const newLevel =
      Player.getLevel(this.state.experience + exp) >
      Player.getLevel(this.state.experience);
    this.setState({
      experience: this.state.experience + exp,
      levelUpDialogOpen: newLevel,
    });
  }

  private changeTheme(appTheme: AppTheme) {
    this.setState({
      theme: appTheme,
      starsNumberColor: this.getTextPrimaryColor(appTheme),
    });
  }

  private setMusicOn(on: boolean) {
    if (this.state.musicOn !== on) {
      this.setState({ musicOn: on });

      const audioElem = this.audioRef.current;
      if (audioElem !== null) {
        if (on) {
          this.playMusic(audioElem);
        } else {
          this.pauseMusic(audioElem);
          audioElem.currentTime = 0;
        }
      }
    }
  }

  private pauseMusic(audio: HTMLAudioElement | null = this.audioRef.current) {
    audio?.pause();
  }

  private playMusic(audio: HTMLAudioElement | null = this.audioRef.current) {
    audio?.play().catch((e) => {
      if (e.name === "NotAllowedError") {
        console.error("Audio autoplay not allowed");
      }

      this.setState({ musicOn: false });
    });
  }

  private getTextPrimaryColor(appTheme: AppTheme = this.state.theme) {
    return appTheme.get().palette.text.primary;
  }

  private getTextSecondaryColor(appTheme: AppTheme = this.state.theme) {
    return appTheme.get().palette.text.secondary;
  }
}
