import { vectorsEqualUnsafe } from "../Utils";
import GameConfig from "./GameConfig";
import GameMode from "./GameMode";

const CLASS_NAME_BLACK = "black";
const CLASS_NAME_RED = "red";
const CLASS_NAME_GREEN = "green";
const CLASS_NAME_BLUE = "blue";
const CLASS_NAME_YELLOW = "yellow";
const CLASS_NAME_PINK = "pink";
const CLASS_NAME_CYAN = "cyan";
const CLASS_NAME_WHITE = "white";

export const MAX_DISTANCE_UNLIMITED = -1;

export default class ColorHolder {
  readonly id: number;
  readonly color: Color;
  readonly maxDistance: number;

  transform = "";
  opacity = 1;

  constructor(id: number, color: Color, gameConfig: GameConfig) {
    this.id = id;
    this.color = color;
    this.maxDistance = gameConfig.gameMode.getMaxDistance(
      color,
      gameConfig.boardSize
    );
  }

  getHtmlElement() {
    return document.getElementById(this.getHtmlElementId());
  }

  getHtmlElementId() {
    return `holder-${this.id}`;
  }

  hasLimitedDistance() {
    return this.maxDistance !== MAX_DISTANCE_UNLIMITED;
  }
}

export class Color {
  readonly rgb: number[];
  readonly className: string;
  readonly symbol: string;

  private constructor(rgb: number[], className: string, symbol: string) {
    this.rgb = rgb;
    this.className = className;
    this.symbol = symbol;
  }

  static fromRgb(rgb: number[]): Color | undefined {
    return Color.values.find((c) => vectorsEqualUnsafe(c.rgb, rgb));
  }

  mixWith(other: Color, gameMode: GameMode): Color | undefined {
    return gameMode.mixColors(this, other);
  }

  static readonly BLACK = new Color([0, 0, 0], CLASS_NAME_BLACK, "\u05E9");
  static readonly RED = new Color([1, 0, 0], CLASS_NAME_RED, "\u05D0");
  static readonly GREEN = new Color([0, 1, 0], CLASS_NAME_GREEN, "\u05D9");
  static readonly BLUE = new Color([0, 0, 1], CLASS_NAME_BLUE, "\u05DB");
  static readonly YELLOW = new Color([1, 1, 0], CLASS_NAME_YELLOW, "\u05E6");
  static readonly PINK = new Color([1, 0, 1], CLASS_NAME_PINK, "\u05D5");
  static readonly CYAN = new Color([0, 1, 1], CLASS_NAME_CYAN, "\u05D8");
  static readonly WHITE = new Color([1, 1, 1], CLASS_NAME_WHITE, "\u05DC");

  static readonly values = [
    Color.BLACK,
    Color.RED,
    Color.GREEN,
    Color.BLUE,
    Color.YELLOW,
    Color.PINK,
    Color.CYAN,
    Color.WHITE,
  ];
}
