import React from "react";
import ColorHolder from "./ColorHolder";
import GameBoard, { GameBoardField } from "./GameBoard";

export function renderGameBoard(board: GameBoard) {
  const rows = [];
  const size = board.config.boardSize;
  for (let y = 0; y < size; ++y) {
    const fields = [];
    for (let x = 0; x < size; ++x) {
      const field = renderGameBoardField(board.fields[x][y], size);
      fields.push(field);
    }

    const positionClass = y === 0 ? "first" : y === size - 1 ? "last" : "";
    rows.push(
      <div key={y} className={`y-${y} board-row ${positionClass}`}>
        {fields}
      </div>
    );
  }
  return rows;
}

export function renderGameBoardField(field: GameBoardField, size: number) {
  const positionClass =
    field.x === 0 ? "first" : field.x === size - 1 ? "last" : "";
  return (
    <div
      id={field.getHtmlElementId()}
      key={field.getHtmlElementId()}
      className={`board-field ${positionClass}`}
    >
      {field.colorHolder ? renderColorHolder(field.colorHolder) : null}
    </div>
  );
}

export function renderColorHolder(holder: ColorHolder) {
  return (
    <div
      id={holder.getHtmlElementId()}
      style={{ opacity: holder.opacity, transform: holder.transform }}
      className={`color ${holder.color.className}`}
    >
      <div className="texture">
        <div className="shadow">
          <span className="symbol">{holder.color.symbol}</span>
        </div>
      </div>
    </div>
  );
}

/* export function renderColorHolderAsString(holder: ColorHolder) {
  return `<div id="${holder.getHtmlElementId()}" class="${`color ${holder.color.className}`}"><div class="texture"><div class="shadow"><span class="symbol">${
    holder.color.symbol
  }</span></div></div></div>`;
}
 */
