import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { Component } from "react";
import LocalizedStrings from "react-localization";

const localStr = new LocalizedStrings({
  en: {
    dialogButtonNegative: "Decline",
    dialogButtonPositive: "Sign in",
    dialogText:
      "I have to tell you that I use Google Drive only to save your game progress and settings. I don't spy on my dear players or read their files. By signing in you're not giving me such permission anyway so you can rest easy. On your drive I only create private files which you can delete at any time (thus losing the ability to sync your progress on other devices.) Also, by signing in you agree with my privacy policy and terms & conditions of use.",
    dialogTitle: "Before you sign in",
  },
  pl: {
    dialogButtonNegative: "Odmów",
    dialogButtonPositive: "Zaloguj się",
    dialogText:
      "Muszę Cię poinformować, że używam Google Drive wyłącznie po to, aby zachować Twój postęp w grze i ustawienia. Nie wściubiam nosa w czyjeś pliki, zresztą logując się nie dajesz mi takich uprawnień, więc możesz spać spokojnie. Na Twoim dysku tworzę jedynie prywatne pliki, które możesz w każdej chwili usunąć (tracąc tym samym możliwość synchronizacji postępu na innych urządzeniach). Logując się zgadzasz się z moją polityką prywatności oraz zasadami i warunkami użytkowania.",
    dialogTitle: "Zanim się zalogujesz",
  },
});

interface Props {
  open: boolean;
  onResult: (accepted: boolean) => void;
}

interface State {}

export default class GoogleSignInDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { stars: 0 };
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return newProps.open !== this.props.open;
  }

  render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>{localStr.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText color="textPrimary">
            {localStr.dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => this.props.onResult(false)}>
            {localStr.dialogButtonNegative}
          </Button>
          <Button color="primary" onClick={() => this.props.onResult(true)}>
            {localStr.dialogButtonPositive}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
