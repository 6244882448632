import {
  Box,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import React, { Component } from "react";
import Stats from "../game/Stats";
import str from "../StringResources";
import KVDb from "../db/KVDb";
import { StatsEntry } from "../db/KVDbEntries";
import AdCardComponent from "./core/AdCardComponent";

interface Props {}

interface State {
  stats: Stats[];
}

export default class StatsComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const keys = ["Rounds", "Points", "StarsGained", "Moves" /* , "Friends" */];

    this.state = {
      stats: this.generateStats(keys),
    };
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return newState !== this.state;
  }

  componentDidMount() {
    KVDb.open()
      .then((db) => {
        this.state.stats.forEach((stats) => {
          db.get(StatsEntry(stats.key)).then((val) => {
            stats.value = val || 0;
            this.setState({ stats: this.state.stats });
          });
        });
      })
      .finally(() => KVDb.close());
  }

  render() {
    return (
      <div>
        <Box m={2}>
          <Paper elevation={2}>
            <List dense>
              {this.state.stats.map((stats) => (
                <ListItem key={stats.key}>
                  <ListItemText
                    primary={stats.primaryText}
                    secondary={stats.secondaryText}
                  />
                  <ListItemSecondaryAction>
                    {stats.value !== undefined
                      ? stats.value
                      : `${str.loading}…`}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Box>
        <AdCardComponent />
      </div>
    );
  }

  private generateStats(keys: string[]) {
    return keys.map(
      (key) =>
        new Stats(
          key,
          str.getString(`stats${key}Primary`),
          str.getString(`stats${key}Secondary`)
        )
    );
  }
}
