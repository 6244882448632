import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PaletteOutlinedIcon from "@material-ui/icons/PaletteOutlined";
import React, { Component } from "react";
import str from "../../StringResources";
import { isAdBlockerActive } from "../../Utils";
import "./styles/AdCardComponent.css";

interface Props {}

interface State {
  isEnabled: boolean;
  isBlocked: boolean;
  unblockAdsDialogOpen: boolean;
  aboutAdsDialogOpen: boolean;
}

export default class AdCardComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isEnabled: false, // TODO check if version without ads
      isBlocked: isAdBlockerActive(),
      unblockAdsDialogOpen: false,
      aboutAdsDialogOpen: false,
    };
  }

  render() {
    return !this.state.isEnabled ? null : (
      <div>
        <Box m={2}>
          <Card elevation={2}>
            {this.state.isBlocked ? (
              <div>
                <CardActionArea
                  onClick={() => window.open("https://kwezal.com", "_blank")}
                >
                  <CardMedia
                    className="kwezal-banner-image"
                    image="/graphics/kwezal-banner.png"
                  />
                </CardActionArea>

                <Box px={1} pt={2} pb={1}>
                  {str.adsAltText}
                </Box>
              </div>
            ) : (
              this.createAd()
            )}
            <Box p={1}>
              {!this.state.isBlocked ? null : (
                <Box pb={1}>
                  <Button
                    className="start-icon-button tiny-button"
                    startIcon={<LockOpenIcon />}
                    size="small"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                      this.setState({ unblockAdsDialogOpen: true });
                    }}
                  >
                    {str.adsUnblockButton}
                  </Button>
                </Box>
              )}
              <Box pb={1}>
                <Button
                  className="start-icon-button tiny-button"
                  startIcon={<InfoIcon />}
                  size="small"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    this.setState({ aboutAdsDialogOpen: true });
                  }}
                >
                  {str.adsAboutButton}
                </Button>
              </Box>
            </Box>
          </Card>
        </Box>
        <Dialog open={this.state.aboutAdsDialogOpen}>
          <DialogTitle>{str.adsAboutTitle}</DialogTitle>
          <DialogContent>
            <div>{str.adsAboutText0}</div>
            <Box pt={2}>{str.adsAboutText1}</Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => this.setState({ aboutAdsDialogOpen: false })}
            >
              {str.close}
            </Button>
          </DialogActions>
        </Dialog>
        {!this.state.isBlocked ? null : (
          <Dialog open={this.state.unblockAdsDialogOpen}>
            <DialogTitle>{str.adsUnblockTitle}</DialogTitle>
            <DialogContent>
              {str.adsUnblockText}
              <List dense>
                {[
                  str.adsUnblockItem0,
                  str.adsUnblockItem1,
                  str.adsUnblockItem2,
                  str.adsUnblockItem3,
                  str.adsUnblockItem4,
                ].map((item) => (
                  <ListItem className="no-padding">
                    <ListItemIcon>
                      <PaletteOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
            </DialogContent>

            <DialogActions>
              <Button
                color="primary"
                onClick={() => this.setState({ unblockAdsDialogOpen: false })}
              >
                {str.close}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }

  private createAd() {
    return (
      <div>
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-4093540123612418"
          data-ad-slot="1931616546"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
      </div>
    );
  }
}
