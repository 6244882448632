import GameMode from "./GameMode";

export default class GameConfig {
  readonly boardSize: number;
  readonly gameMode: GameMode;

  constructor(gameMode: GameMode, boardSize: number = 5) {
    this.gameMode = gameMode;
    this.boardSize = boardSize;
  }
}
