import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { Component } from "react";
import { sprintf } from "sprintf-js";
import KVDb from "../../db/KVDb";
import { SharedUrlsEntry } from "../../db/KVDbEntries";
import str from "../../StringResources";

interface Props {
  open: boolean;
  url: string;
  starsReward: number;
  onClose: () => void;
  onStarsAdded: (stars: number) => void;
}

interface State {
  verificationText: string;
  invalidVerificationText: boolean;
}

export default class SharingVerificationDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { verificationText: "", invalidVerificationText: false };
  }

  render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>{str.verification}</DialogTitle>
        <DialogContent>
          <DialogContentText color="textPrimary">
            {sprintf(str.formatVerificationDialogText, this.props.starsReward)}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label={
              this.state.invalidVerificationText ? str.error : str.sharedPostUrl
            }
            type="text"
            value={this.state.verificationText}
            inputProps={{ maxLength: 255 }}
            onInput={(e) =>
              this.setState({
                verificationText: (e.target as any).value,
                invalidVerificationText: false,
              })
            }
            fullWidth
            error={this.state.invalidVerificationText}
            helperText={
              this.state.invalidVerificationText
                ? str.errorVerificationText
                : undefined
            }
          />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => this.props.onClose()}>
            {str.resign}
          </Button>
          <Button color="primary" onClick={() => this.verify()}>
            {str.verify}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private verify() {
    const url = this.props.url;
    const websiteUrl = url.substring(
      0,
      url.indexOf("/", url.indexOf("://") + 3) + 1
    );
    const unifiedWebsiteUrl = this.unifyUrl(websiteUrl);
    const unifiedVerificationUrl = this.unifyUrl(this.state.verificationText);

    const legit = unifiedVerificationUrl.startsWith(unifiedWebsiteUrl);

    if (legit && url !== "") {
      this.saveSharedUrl(url);
      this.props.onStarsAdded(this.props.starsReward);
      this.props.onClose();
    } else {
      this.setState({ invalidVerificationText: true });
    }
  }

  private unifyUrl(url: string) {
    url = url.trimLeft();
    const protocolStart = url.indexOf("://");
    const protocolEnd = protocolStart + 3;

    if (protocolStart === -1) {
      return url;
    } else if (url.substr(protocolEnd, 4) !== "www.") {
      url =
        url.substring(0, protocolEnd) +
        "www." +
        url.substring(protocolEnd, url.length);
    }

    if (url.substring(0, protocolStart) === "http") {
      url = "https" + url.substring(protocolStart, url.length);
    }

    return url;
  }

  private saveSharedUrl(url: string) {
    KVDb.open()
      .then(async (db) => {
        const sharedUrlsEntry = SharedUrlsEntry();
        const sharedString = await db.get(sharedUrlsEntry);
        let sharedUrls: string[];
        if (sharedString === null) {
          sharedUrls = [url];
        } else {
          sharedUrls = JSON.parse(sharedString) as string[];
          sharedUrls.push(url);
        }

        db.set(sharedUrlsEntry, JSON.stringify(sharedUrls));
      })
      .finally(() => KVDb.close());
  }
}
