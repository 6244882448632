import React, { Component, PureComponent } from "react";
import { sprintf } from "sprintf-js";
import GameConfig from "../game/GameConfig";
import { GAME_MODES } from "../game/GameMode";
import Player from "../game/Player";
import str from "../StringResources";
import AdCardComponent from "./core/AdCardComponent";
import GameModeComponent from "./core/GameModeComponent";

interface Props {
  experience: number;
  onGameConfigChange: (config: GameConfig) => void;
}

// TODO expanded not as name but as some enum of game mode
interface State {
  expandedMode: string;
}

const IS_NEW_MODE_COMING_SOON = true;
const NO_MODE_EXPANDED = "";

export default class GameModesComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { expandedMode: NO_MODE_EXPANDED };
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return (
      newProps.experience !== this.props.experience ||
      newState.expandedMode !== this.state.expandedMode
    );
  }

  render() {
    console.log(`game modes`);
    return (
      <div>
        <AdCardComponent />
        {GAME_MODES.map((mode) => (
          <GameModeComponent
            lock={this.getLevelLock(mode.requiredLevel)}
            mode={mode}
            image={mode.image}
            expanded={this.state.expandedMode === mode.id}
            onExpand={(expanded) => {
              this.onExpand(mode.id, expanded);
            }}
            onGameConfigChange={(mode) => {
              this.props.onGameConfigChange(new GameConfig(mode));
            }}
          />
        ))}
        {IS_NEW_MODE_COMING_SOON && (
          <GameModeComponent
            lock={this.getSoonLock()}
            mode={GAME_MODES[0]}
            image=""
            expanded={false}
            onExpand={() => {}}
            onGameConfigChange={(mode) => {
              this.props.onGameConfigChange(new GameConfig(mode));
            }}
          />
        )}

        <AdCardComponent />
      </div>
    );
  }

  private onExpand(modeId: string, expanded: boolean) {
    if (!expanded) {
      this.setState({ expandedMode: NO_MODE_EXPANDED });
    } else {
      this.setState({ expandedMode: modeId });
    }
  }

  private getLevelLock(level: number) {
    return Player.getLevel(this.props.experience) >= level
      ? undefined
      : sprintf(str.formatLevelRequired, level);
  }

  private getSoonLock() {
    return `${str.comingSoon}!`;
  }
}
