import vkIcon from "@iconify/icons-mdi/vk";
import { Icon } from "@iconify/react";
import {
  IconButton,
  Link,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FacebookIcon from "@material-ui/icons/Facebook";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import RedditIcon from "@material-ui/icons/Reddit";
// import AppleIcon from "@material-ui/icons/Apple";
import TwitterIcon from "@material-ui/icons/Twitter";
import React, { Component } from "react";
import LocalizedStrings from "react-localization";
import { sprintf } from "sprintf-js";
import KVDb from "../../db/KVDb";
import { SharedUrlsEntry } from "../../db/KVDbEntries";
import str from "../../StringResources";
import SharingVerificationDialog from "../dialogs/SharingVerificationDialog";

const WEBSITE_URL = "https://fusia.kwezal.com/";
const SNACKBAR_DURATION = 5000;
const STARS_REWARD = 15;

const localStr = new LocalizedStrings({
  en: {
    dontSeeShareOptions: "Share options doesn't show up?",
  },
  pl: {
    dontSeeShareOptions: "Opcje udostępniania nie wyświetlają się?",
  },
});

interface Props {
  anchor: HTMLElement | null;
  onClose: () => void;
  onStarsChanged: (stars: number) => void;
}

interface State {
  shareUrl: string | null;
  isClipboardSnackbarOpen: boolean;
  sharedUrls: string[];
}

export default class ShareMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      shareUrl: null,
      isClipboardSnackbarOpen: false,
      sharedUrls: [],
    };
  }

  render() {
    return (
      <div>
        <Menu
          id="simple-menu"
          anchorEl={this.props.anchor}
          open={this.props.anchor !== null}
          onEnter={() => this.loadSharedUrls()}
          onClose={() => this.props.onClose()}
        >
          <ListSubheader>{str.share}</ListSubheader>
          <MenuItem
            onClick={() => {
              this.copyUrlToClipboard();
              this.setState({
                isClipboardSnackbarOpen: true,
              });
              this.props.onClose();
            }}
          >
            <ListItemIcon>
              <FileCopyIcon />
            </ListItemIcon>
            <ListItemText>{WEBSITE_URL}</ListItemText>
            <input
              id="websiteUrl"
              type="text"
              style={{ opacity: 0, position: "absolute" }}
              value={WEBSITE_URL}
              readOnly
            />
          </MenuItem>
          {this.createSocialLink(
            str.shareFacebook,
            <FacebookIcon htmlColor="#3b5998" />,
            `https://www.facebook.com/sharer/sharer.php?u=${WEBSITE_URL}`
          )}
          {this.createSocialLink(
            str.shareVKontakte,
            <Icon icon={vkIcon} width={24} color="#4c75a3" />,
            `https://vk.com/share.php?url=${WEBSITE_URL}`
          )}
          {this.createSocialLink(
            str.shareReddit,
            <RedditIcon htmlColor="#ff4500" />,
            `https://www.reddit.com/submit?url=${WEBSITE_URL}`
          )}
          {this.createSocialLink(
            str.tweet,
            <TwitterIcon htmlColor="#00acee" />,
            `https://twitter.com/intent/tweet?url=${WEBSITE_URL}`
          )}
          <MenuItem>
            <ListItemText primary={localStr.dontSeeShareOptions} />
          </MenuItem>
        </Menu>
        {this.state.sharedUrls.length === 4 ? null : (
          <SharingVerificationDialog
            open={this.state.shareUrl !== null}
            url={this.state.shareUrl || ""}
            starsReward={STARS_REWARD}
            onClose={() => this.setState({ shareUrl: null })}
            onStarsAdded={(stars) => this.props.onStarsChanged(stars)}
          />
        )}
        <Snackbar
          open={this.state.isClipboardSnackbarOpen}
          autoHideDuration={SNACKBAR_DURATION}
          onClose={() => this.closeClipboardSnackbar()}
          message={sprintf(str.formatCopiedToClipboard, WEBSITE_URL)}
          action={
            <IconButton
              size="small"
              color="inherit"
              onClick={() => this.closeClipboardSnackbar()}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </div>
    );
  }

  private loadSharedUrls() {
    KVDb.open()
      .then(async (db) => {
        const shared = await db.get(SharedUrlsEntry());
        if (shared !== null) {
          this.setState({ sharedUrls: JSON.parse(shared) as string[] });
        }
      })
      .finally(() => KVDb.close());
  }

  private createSocialLink(text: string, icon: JSX.Element, href: string) {
    const isShared = this.state.sharedUrls.includes(href);
    return (
      <Link
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        color="inherit"
      >
        <MenuItem
          onClick={() => {
            if (!isShared) {
              this.setState({
                shareUrl: href,
              });
            }

            this.props.onClose();
          }}
        >
          <ListItemIcon>{icon} </ListItemIcon>
          <ListItemText primary={text} />
          {isShared ? null : (
            <ListItemSecondaryAction>
              +{STARS_REWARD}
              <span role="img" aria-label="star">
                ⭐
              </span>
            </ListItemSecondaryAction>
          )}
        </MenuItem>
      </Link>
    );
  }

  private copyUrlToClipboard() {
    const urlElem = document.getElementById("websiteUrl") as HTMLInputElement;
    urlElem.select();
    urlElem.setSelectionRange(0, urlElem.value.length);
    document.execCommand("copy");
  }

  private closeClipboardSnackbar() {
    this.setState({ isClipboardSnackbarOpen: false });
  }
}
