import IKVDb from "./IKVDb";
import WebStorageKVDb from "./WebStorageKVDb";
import { IKVDbEntry } from "./KVDbTypes";

export default class KVDb {
  private static instance?: IKVDb;
  private static openCount = 0;

  private constructor() {}

  static open(): Promise<KVDb> {
    ++KVDb.openCount;

    if (KVDb.instance !== undefined) return Promise.resolve(new KVDb());

    // TODO check if browser or Android or iOS
    const idb = WebStorageKVDb.open();
    KVDb.instance = idb;
    return Promise.resolve(new KVDb());
  }

  static close() {
    KVDb.openCount--;
    if (KVDb.openCount <= 0 && KVDb.instance !== undefined) {
      KVDb.instance.close();
      KVDb.instance = undefined;
      KVDb.openCount = 0;
    }
  }

  get<ValueType>(key: IKVDbEntry<ValueType>) {
    const instance = this.getInstance();
    if (instance === undefined) {
      return Promise.resolve(null);
    } else {
      return key.get(KVDb.instance!);
    }
  }

  getOrDefault<ValueType>(key: IKVDbEntry<ValueType>, defaultValue: ValueType) {
    return this.get(key).then((value) =>
      value !== null ? value : defaultValue
    );
  }

  getAll() {
    const instance = this.getInstance();
    if (instance === undefined) {
      return Promise.resolve(null);
    } else {
      return KVDb.instance!.getAll();
    }
  }

  set<ValueType>(key: IKVDbEntry<ValueType>, value: ValueType) {
    const instance = this.getInstance();
    if (instance === undefined) {
      return Promise.resolve(false);
    } else {
      return key.set(KVDb.instance!, value);
    }
  }

  add(key: IKVDbEntry<number>, value: number) {
    const instance = this.getInstance();
    if (instance === undefined) {
      return Promise.resolve(false);
    } else {
      return key
        .get(KVDb.instance!)
        .then((val) =>
          key.set(KVDb.instance!, (val !== null ? val : 0) + value)
        );
    }
  }

  private getInstance() {
    if (KVDb.instance === undefined) {
      console.error("Database instance is undefined");
    }
    return KVDb.instance;
  }
}
