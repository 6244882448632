import LocalizedStrings from "react-localization";
import { addVectorsUnsafe } from "../Utils";
import ColorHolder, { Color, MAX_DISTANCE_UNLIMITED } from "./ColorHolder";
import Game from "./Game";

const localStr = new LocalizedStrings({
  en: {
    darkMatterDescription:
      "Dark matter is everywhere. In this game. Everywhere.",
    darkMatterName: "Dark Matter",
    darkMatterRules:
      "Colorful balls connect with each other\nBlack balls absorb colorful and white balls\nWhite balls move maximum number of squares horizontally or vertically\nBlack balls move one square horizontally or vertically\nColorful balls move the number of squares depending on their color: red, green, blue = 2, yellow, pink, cyan = 3\nThe game ends when the whole board is filled with balls\nEach absorbed ball gives the number of points depending on its color: red, green, blue = 10; yellow, pink, cyan = 30; white = 100",
    paintItWhiteDescription:
      "I wanna see it painted, painted white. White as dawn, white as snow.",
    paintItWhiteName: "Paint It, White",
    paintItWhiteRules:
      "Colorful balls connect with each other\nBlack balls absorb colorful balls\nWhite balls absorb black balls\nBlack and colorful balls move maximum number of squares horizontally or vertically\nWhite balls move one square horizontally or vertically\nThe game ends when the whole board is filled with balls\nEach ball gives the number of points depending on its color: black = 0; red, green, blue = 10; yellow, pink, cyan = 30; white = 100",
  },
  pl: {
    darkMatterDescription:
      "Ciemna materia jest wszędzie. W tej grze. Wszędzie.",
    darkMatterName: "Ciemna materia",
    darkMatterRules:
      "Kolorowe kule łączą się ze sobą\nCzarne kule absorbują kolorowe i białe\nBiałe kule ruszają się o maksymalną liczbę pól poziomo lub pionowo\nCzarne kule ruszają się o jedno pole poziomo lub pionowo\nKolorowe kule ruszają się o liczbę pól zależną od ich koloru: czerwone, zielone, niebieskie = 2, żółte, różowe, turkusowe = 3\nGra kończy się, kiedy cała plansza jest wypełniona kulami\nKażda zaabsorbowana kula daje ilość punktów uzależnioną od jej koloru: czerwona, zielona, niebieska = 10; żółta, różowa, turkusowa = 30; biała = 100",
    paintItWhiteDescription:
      "Chcę zobaczyć to pomalowane, pomalowane na biało. Bielą świtu, bielą śniegu.",
    paintItWhiteName: "Paint It, White",
    paintItWhiteRules:
      "Kolorowe kule łączą się ze sobą\nCzarne kule absorbują kolorowe\nBiałe kule absorbują czarne\nCzarne i kolorowe kule ruszają się o maksymalną liczbę pól poziomo lub pionowo\nBiałe kule ruszają się o jedno pole poziomo lub pionowo\nGra kończy się, kiedy cała plansza jest wypełniona kulami\nKażda kula daje ilość punktów uzależnioną od jej koloru: czarna = 0; czerwona, zielona, niebieska = 10; żółta, różowa, turkusowa = 30; biała = 100",
  },
});

export default abstract class GameMode {
  abstract readonly id: string;
  abstract readonly image: string;
  abstract readonly requiredLevel: number;
  abstract readonly minPoints: number;
  abstract readonly maxPoints: number;
  abstract getMaxDistance(color: Color, boardSize: number): number;
  abstract mixColors(color0: Color, color1: Color): Color | undefined;
  abstract calculatePoints(game: Game): number;

  onFusion(attacker: ColorHolder, defender: ColorHolder, result: Color) {}

  getName() {
    return localStr.getString(`${this.id}Name`);
  }

  getDescription() {
    return localStr.getString(`${this.id}Description`);
  }

  getRules() {
    return localStr.getString(`${this.id}Rules`);
  }
}

export class PaintItWhiteMode extends GameMode {
  readonly id = "paintItWhite";
  readonly image = "snow-fox.jpg";
  readonly requiredLevel = 1;
  readonly minPoints = 0; // all black
  readonly maxPoints = 2410; // 24 white, 1 RGB

  getMaxDistance(color: Color, boardSize: number) {
    if (color === Color.WHITE) return 1;
    else return MAX_DISTANCE_UNLIMITED;
  }

  mixColors(color0: Color, color1: Color): Color | undefined {
    const arr = [color0, color1];

    // black
    if (arr.find((c) => c === Color.BLACK)) {
      // white absorbs black
      if (arr.find((c) => c === Color.WHITE)) return Color.WHITE;
      // black absorbs colorful
      else if (arr.find((c) => c !== Color.BLACK)) return Color.BLACK;
      // black blocks black
      else return undefined;
    }

    // white
    if (arr.find((c) => c === Color.WHITE)) {
      // white blocks white and colorful
      return undefined;
    }

    // both colorful
    const mixedRgb = addVectorsUnsafe(color0.rgb, color1.rgb);
    return Color.fromRgb(mixedRgb);
  }

  calculatePoints(game: Game) {
    let overallPoints = 0;
    game.board.fields.forEach((row) => {
      row.forEach((field) => {
        if (field.colorHolder !== undefined) {
          let colorPoints = 0;
          switch (field.colorHolder.color) {
            case Color.WHITE:
              colorPoints = 100;
              break;
            case Color.BLACK:
              colorPoints = 0;
              break;
            case Color.RED:
            case Color.GREEN:
            case Color.BLUE:
              colorPoints = 10;
              break;
            case Color.YELLOW:
            case Color.PINK:
            case Color.CYAN:
              colorPoints = 30;
              break;
          }
          overallPoints += colorPoints;
        }
      });
    });
    return overallPoints;
  }
}

export class DarkMatterMode extends GameMode {
  readonly id = "darkMatter";
  readonly image = "galaxy-cluster.jpg";
  readonly requiredLevel = 2;
  readonly minPoints = 720; // (R+G+B) * 24
  readonly maxPoints = 2260; // 22 white + 6RGB

  private gainedPointsHistory: number[] = [];

  getMaxDistance(color: Color, boardSize: number) {
    switch (color) {
      case Color.BLACK:
        return 1;
      case Color.WHITE:
        return MAX_DISTANCE_UNLIMITED;
      default:
        return color.rgb.reduce((prev, curr) => prev + curr) + 1;
    }
  }

  mixColors(color0: Color, color1: Color): Color | undefined {
    const arr = [color0, color1];

    // black
    if (arr.find((c) => c === Color.BLACK)) {
      // black absorbs all
      if (arr.find((c) => c !== Color.BLACK)) return Color.BLACK;
      // black blocks black
      else return undefined;
    }

    // both colorful
    const mixedRgb = addVectorsUnsafe(color0.rgb, color1.rgb);
    return Color.fromRgb(mixedRgb);
  }

  calculatePoints(game: Game) {
    return this.gainedPointsHistory.reduce((prev, curr) => prev + curr, 0);
  }

  onFusion(attacker: ColorHolder, defender: ColorHolder, result: Color) {
    const composed = [attacker, defender];
    const blackIndex = composed.findIndex(
      (holder) => holder.color === Color.BLACK
    );
    if (blackIndex !== -1) {
      const colorfulHolder = composed[blackIndex === 0 ? 1 : 0];
      this.gainedPointsHistory.push(
        this.getPointsByColor(colorfulHolder.color)
      );
    }
  }

  private getPointsByColor(color: Color) {
    switch (color) {
      case Color.WHITE:
        return 100;
      case Color.RED:
      case Color.GREEN:
      case Color.BLUE:
        return 10;
      case Color.YELLOW:
      case Color.PINK:
      case Color.CYAN:
        return 30;
    }
    return 0;
  }
}

/*
colors absorb white
const arr = [color0, color1];

    // black
    if (arr.find((c) => c === Color.BLACK)) {
      // white absorbs black
      if (arr.find((c) => c === Color.WHITE)) return Color.WHITE;
      // black absorbs colorful
      else if (arr.find((c) => c !== Color.BLACK)) return Color.BLACK;
      // black blocks black
      else return undefined;
    }

    // white
    if (arr.find((c) => c === Color.WHITE)) {
      const other = arr.find((c) => c !== Color.WHITE);
      // colorful absorbs white
      if (other !== undefined) return other;
      // white blocks white
      else return undefined;
    }

    // both colorful
    const mixedRgb = addVectorsUnsafe(color0.rgb, color1.rgb);
    return Color.fromRgb(mixedRgb);
*/

export const GAME_MODES = [new PaintItWhiteMode(), new DarkMatterMode()];
