import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import PaletteOutlinedIcon from "@material-ui/icons/PaletteOutlined";
import React, { Component } from "react";
import { sprintf } from "sprintf-js";
import { GAME_MODES } from "../../game/GameMode";
import str from "../../StringResources";

interface Props {
  open: boolean;
  level: number;
  onClose: () => void;
}

interface State {}

export default class LevelUpDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { stars: 0 };
  }

  render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>{str.levelUp}!</DialogTitle>
        <div className="text-center">
          <img src="/graphics/ui/level-up-dialog.png" alt="" />
        </div>
        <DialogContent>
          <DialogContentText color="textPrimary">
            {sprintf(str.formatLevelUp, this.props.level)}
          </DialogContentText>
          {this.displayNewCapabilities()}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => this.onClose()}>
            {str.great}!
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private displayNewCapabilities() {
    const capabilities = [str.levelUpUndoLimitIncreased];

    if (this.isNewModeUnlocked()) {
      capabilities.push(str.levelUpUnlockedGameMode);
    }

    return (
      <List dense>
        {capabilities.map((capability, i) => (
          <ListItem key={i}>
            <ListItemIcon>
              <PaletteOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={capability} />
          </ListItem>
        ))}
      </List>
    );
  }

  private isNewModeUnlocked() {
    return (
      GAME_MODES.find((mode) => mode.requiredLevel === this.props.level) !==
      undefined
    );
  }

  private onClose() {
    this.props.onClose();
  }
}
