import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PaletteOutlinedIcon from "@material-ui/icons/PaletteOutlined";
import React, { Component } from "react";
import LocalizedStrings from "react-localization";
import { Link } from "react-router-dom";
import GameMode from "../../game/GameMode";
import { ROUTE_GAME } from "../../Routes";
import str from "../../StringResources";
import "./styles/GameModeComponent.css";

const localStr = new LocalizedStrings({
  en: {
    hideRules: "Hide rules",
    showRules: "Show rules",
  },
  pl: {
    hideRules: "Ukryj zasady",
    showRules: "Pokaż zasady",
  },
});

interface Props {
  mode: GameMode;
  lock?: string;
  image: string;
  expanded: boolean;
  onExpand: (expanded: boolean) => void;
  onGameConfigChange: (mode: GameMode) => void;
}

interface State {}

export default class GameModeComponent extends Component<Props, State> {
  shouldComponentUpdate(newProps: Props, newState: State) {
    return (
      newProps.lock !== this.props.lock ||
      newProps.expanded !== this.props.expanded
    );
  }

  render() {
    return (
      <Box m={2}>
        <Card className={this.isLocked() ? "locked" : ""}>
          <CardActionArea
            component={Link}
            to={this.isLocked() ? "/" : `/${ROUTE_GAME}`}
            onClick={() => {
              if (!this.isLocked()) {
                this.props.onGameConfigChange(this.props.mode);
              }
            }}
            disabled={this.isLocked()}
          >
            <CardMedia
              className="game-mode-image"
              image={`/graphics/game-modes/${
                this.isLocked() ? "locked-game-mode.jpg" : this.props.image
              }`}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className="game-mode-name"
              >
                {this.isLocked()
                  ? str.gameModeLockedName
                  : this.props.mode.getName()}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className="game-mode-description"
              >
                {this.isLocked()
                  ? str.gameModeLockedDescription
                  : this.props.mode.getDescription()}
              </Typography>
              <Box my={2} mx={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disabled={this.isLocked()}
                  className="game-mode-play-button"
                >
                  {str.play}
                </Button>
              </Box>
            </CardContent>
          </CardActionArea>
          {!this.isLocked() && (
            <div>
              <CardActionArea>
                <CardActions
                  className="no-padding"
                  onClick={() => {
                    this.props.onExpand(!this.props.expanded);
                  }}
                >
                  <CardContent>
                    <Typography
                      className="bold"
                      component="span"
                      color="secondary"
                    >
                      {this.props.expanded
                        ? localStr.hideRules
                        : localStr.showRules}
                    </Typography>
                  </CardContent>
                  <IconButton
                    className={`game-mode-expand-button${
                      this.props.expanded ? " expanded" : ""
                    }`}
                    disabled
                  >
                    <ExpandMoreIcon color="secondary" />
                  </IconButton>
                </CardActions>
              </CardActionArea>
              <Collapse in={this.props.expanded} timeout="auto">
                <CardContent>{this.displayRules()}</CardContent>
              </Collapse>
            </div>
          )}
          {this.isLocked() && (
            <div className="game-mode-requirements-container">
              <Box className="game-mode-requirements" p={2}>
                <Typography variant="h5" component="span">
                  {this.getLockText()}
                </Typography>
              </Box>
            </div>
          )}
        </Card>
      </Box>
    );
  }

  private displayRules() {
    return (
      <List dense>
        {this.props.mode
          .getRules()
          .split("\n")
          .map((rule, i) => (
            <ListItem key={i}>
              <ListItemIcon>
                <PaletteOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={rule} />
            </ListItem>
          ))}
      </List>
    );
  }

  private isLocked() {
    return this.props.lock !== undefined;
  }

  private getLockText() {
    return this.props.lock;
  }
}
