import { Location } from "history";
import React, { Component, HTMLAttributes } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { CSS_VAR_ROUTER_TRANSITION_DURATION } from "../../Config";
import { cssTimeVar } from "../../Utils";
import "./styles/RouterOutlet.css";

interface Props extends HTMLAttributes<HTMLElement> {
  location?: Location;
}

interface State {}

export default class RouterOutlet extends Component<Props, State> {
  render() {
    return (
      <div className={this.props.className}>
        <TransitionGroup>
          <CSSTransition
            classNames="slide"
            timeout={cssTimeVar(CSS_VAR_ROUTER_TRANSITION_DURATION)}
            key={this.props.location?.key || this.props.location?.pathname}
          >
            {this.props.children}
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}
