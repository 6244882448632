import { CSS_VAR_COLOR_HOLDER_MOVE_DURATION } from "../Config";
import { cssTimeVar, mapRange } from "../Utils";
import GameBoard, { Direction } from "./GameBoard";
import GameConfig from "./GameConfig";
import Move, { ChangeType, ColorHolderChange } from "./Move";

export default class Game {
  readonly config: GameConfig;
  readonly board: GameBoard;
  onGameEnd: () => void;
  onStateChanged: (state: Game) => void;

  points = 0;
  moves = 0;
  undoLeft = 0;

  stars = 0;
  isActive = false;

  private backStack: Move[] = [];

  constructor(config: GameConfig) {
    this.config = config;
    this.board = new GameBoard(config);
    this.onGameEnd = () => {};
    this.onStateChanged = (state: Game) => {};
  }

  start() {
    this.undoLeft = 1;
    this.updateUndoLeft();

    this.isActive = true;
    this.board.summonColorHolder();

    this.onStateChanged(this);
  }

  move(direction: Direction) {
    const move = this.board.moveColorHolders(direction);
    if (move !== undefined) {
      this.onStateChanged(this);

      this.backStack.push(move);
      const backStackElemsToRemove = this.backStack.length - this.undoLeft;
      if (backStackElemsToRemove > 0) {
        this.backStack.splice(0, backStackElemsToRemove);
      }

      setTimeout(() => {
        this.board.replaceColorHolders();
        this.onStateChanged(this);
        this.goToNextRound();
        this.onStateChanged(this);
      }, cssTimeVar(CSS_VAR_COLOR_HOLDER_MOVE_DURATION));
    } else {
      this.isActive = true;
    }
  }

  goToNextRound() {
    this.isActive = true;
    ++this.moves;
    this.updateMoves();

    const field = this.board.summonColorHolder();
    if (field !== undefined && this.undoLeft > 0) {
      this.backStack[this.backStack.length - 1].add(
        new ColorHolderChange(field.colorHolder!, ChangeType.APPEAR, field)
      );
    }

    this.points = this.calculatePoints();
    this.updatePoints();

    if (!this.availableMoveExists()) {
      this.end();
    }
  }

  end() {
    this.isActive = false;
    this.stars = this.getStarsNumber();
    this.board.clear();
    this.onGameEnd();
  }

  undo(): boolean {
    if (this.undoLeft <= 0 || this.backStack.length === 0) {
      return false;
    }

    const move = this.backStack.pop()!;
    const changes = move.colorHolderChanges;

    console.log(`Undo: move=${JSON.stringify(move)}`);
    for (let i = changes.length - 1; i >= 0; --i) {
      this.undoChange(changes[i]);
    }

    this.board.undo();

    --this.moves;
    this.updateMoves();

    this.points = this.calculatePoints();
    this.updatePoints();

    --this.undoLeft;
    this.updateUndoLeft();

    this.onStateChanged(this);
    return true;
  }

  getStarsNumber() {
    const minPoints = this.config.gameMode.minPoints;
    const maxPoints = this.config.gameMode.maxPoints;
    const acceptanceThreshold = (maxPoints - minPoints) * 0.25; // 25%
    const perc = mapRange(
      this.points,
      minPoints + acceptanceThreshold,
      maxPoints,
      0.05,
      1
    );
    return Math.max(0, Math.round(perc * 10) / 2);
  }

  private undoChange(change: ColorHolderChange) {
    change.holder.opacity = 1;
    change.holder.transform = "";
    switch (change.changeType) {
      case ChangeType.MOVE: {
        this.board.remove(change.destinationField!);
        this.board.createColorHolder(change.holder, change.originField);
        /* this.board.moveColorHolder(
          change.destinationField!,
          change.originField
        ); */
        break;
      }
      case ChangeType.APPEAR: {
        this.board.remove(change.originField);
        break;
      }
      case ChangeType.DISAPPEAR: {
        this.board.createColorHolder(change.holder, change.originField);
        break;
      }
    }
  }

  private availableMoveExists() {
    return this.board.availableMoveExists();
  }

  private calculatePoints() {
    return this.config.gameMode.calculatePoints(this);
  }

  private updatePoints() {
    this.getPointsElement().innerText = `${this.points}`;
  }

  private updateMoves() {
    this.getMovesElement().innerText = `${this.moves}`;
  }

  private updateUndoLeft() {
    this.getUndoLeftElement().innerText = `${this.undoLeft}`;
    if (this.undoLeft <= 0) {
      this.getUndoButtonElement().className += " Mui-disabled";
    }
  }

  private getPointsElement() {
    return document.getElementById("points")!;
  }

  private getMovesElement() {
    return document.getElementById("moves")!;
  }

  private getUndoButtonElement() {
    return document.getElementById("undo-button")!;
  }

  private getUndoLeftElement() {
    return document.getElementById("undo-left")!;
  }
}
