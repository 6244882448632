import { GameBoardField } from "./GameBoard";
import ColorHolder from "./ColorHolder";

export default class Move {
  readonly colorHolderChanges: ColorHolderChange[];

  constructor(ballChanges?: ColorHolderChange[]) {
    this.colorHolderChanges = ballChanges || [];
  }

  add(ballChange: ColorHolderChange) {
    this.colorHolderChanges.push(ballChange);
  }
}

export enum ChangeType {
  MOVE,
  APPEAR,
  DISAPPEAR,
}

export class ColorHolderChange {
  readonly holder: ColorHolder;
  readonly originField: GameBoardField;
  readonly destinationField?: GameBoardField;
  readonly changeType: ChangeType;

  constructor(
    holder: ColorHolder,
    changeType: ChangeType,
    originField: GameBoardField,
    destinationField?: GameBoardField
  ) {
    this.holder = holder;
    this.changeType = changeType;
    this.originField = originField;
    this.destinationField = destinationField;
  }
}
