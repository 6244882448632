import Game from "./Game";
import { Direction } from "./GameBoard";

type ControlsFunction = (pressed: boolean, keyCode: string) => boolean;

export default class Controls {
  private readonly game: Game;
  private readonly controlsFunctions: ControlsFunction[] = [];

  constructor(game: Game) {
    this.game = game;
  }

  attach() {
    const colorHoldersControls = this.getColorHoldersControlsFunction();
    this.controlsFunctions.push(colorHoldersControls);

    document.addEventListener("keydown", this.keyDownHandler);
    document.addEventListener("keyup", this.keyUpHandler);
  }

  detach() {
    document.removeEventListener("keydown", this.keyDownHandler);
    document.removeEventListener("keyup", this.keyUpHandler);
  }

  private keyDownHandler = (e: KeyboardEvent) => {
    this.keyPressHandler(true, e);
  };

  private keyUpHandler = (e: KeyboardEvent) => {
    this.keyPressHandler(false, e);
  };

  private keyPressHandler(pressed: boolean, e: KeyboardEvent) {
    this.controlsFunctions.forEach((fun) => {
      if (fun(pressed, e.code)) return;
    });
  }

  private getColorHoldersControlsFunction(): ControlsFunction {
    return (pressed: boolean, keyCode: string) => {
      if (!pressed && this.game.isActive) {
        this.game.isActive = false;
        let direction: Direction;
        switch (keyCode) {
          case "ArrowLeft":
            direction = Direction.LEFT;
            break;
          case "ArrowUp":
            direction = Direction.UP;
            break;
          case "ArrowRight":
            direction = Direction.RIGHT;
            break;
          case "ArrowDown":
            direction = Direction.DOWN;
            break;
          default:
            return false;
        }

        this.game.move(direction);
      }

      return true;
    };
  }
}
