import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { Component } from "react";
import str from "../../StringResources";

interface Props {
  open: boolean;
  onResult: (confirmed: boolean) => void;
}

interface State {}

export default class LeaveGameConfirmationDialog extends Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = { stars: 0 };
  }

  render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>{str.exit}</DialogTitle>
        <DialogContent>
          <DialogContentText color="textPrimary">
            {str.gameLeaveConfirmation}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => this.props.onResult(false)}>
            {str.stay}
          </Button>
          <Button color="inherit" onClick={() => this.props.onResult(true)}>
            {str.leaveGame}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
