import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import ShareIcon from "@material-ui/icons/Share";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import TranslateIcon from "@material-ui/icons/Translate";
import React, { Component } from "react";
import LocalizedStrings from "react-localization";
import str from "../../StringResources";
import ShareMenu from "../menus/ShareMenu";

const LIST_ITEM_COLOR = "secondary";

const localStr = new LocalizedStrings({
  en: {
    dialogText:
      "I'll be very grateful if you share your opinion about me and invite your friends to play with me. 💜 I'll also appreciate the suggestions on how I can improve myself, and if you'd like to help me with this, I'll owe you. ⭐",
    dialogTitle: "Contribute",
    offerHelp: "Offer help",
    rate: "Rate (soon)",
    subjectOfferHelp: "[Help offer]",
    subjectSuggest: "[Change suggestion]",
    subjectTranslate: "[Translation]",
    suggest: "Suggest a change",
    translate: "Add a translation",
  },
  pl: {
    dialogText:
      "Będę bardzo wdzięczna, jeśli wyrazisz swoją opinię na mój temat i zaprosisz do mnie Twoich znajomych. 💜 Docenię także sugestie, co mogłabym w sobie poprawić, a jeśli zechcesz mi w tym pomóc, to na pewno postaram Ci się to odpowiednio wynagrodzić. ⭐",
    dialogTitle: "Wesprzyj",
    offerHelp: "Zaoferuj pomoc",
    rate: "Oceń (wkrótce)",
    subjectOfferHelp: "[Oferta pomocy]",
    subjectSuggest: "[Propozycja zmiany]",
    subjectTranslate: "[Tłumaczenie]",
    suggest: "Zaproponuj zmianę",
    translate: "Dodaj tłumaczenie",
  },
});

type ListItemInfo = {
  disabled?: boolean;
  icon: any;
  text: string;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

interface Props {
  open: boolean;
  onClose: () => void;
  onStarsChanged: (stars: number) => void;
}

interface State {
  shareMenuAnchor: HTMLElement | null;
}

export default class ContributionDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { shareMenuAnchor: null };
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return (
      newProps.open !== this.props.open ||
      newState.shareMenuAnchor !== this.state.shareMenuAnchor
    );
  }

  render() {
    const listItems: ListItemInfo[] = [
      {
        disabled: true,
        icon: <ThumbUpIcon color={LIST_ITEM_COLOR} />,
        text: localStr.rate,
        onClick: () => {},
      },
      {
        icon: <ShareIcon color={LIST_ITEM_COLOR} />,
        text: str.share,
        onClick: (e) => {
          this.setState({ shareMenuAnchor: e.currentTarget });
        },
      },
      {
        icon: <NoteAddIcon color={LIST_ITEM_COLOR} />,
        text: localStr.suggest,
        href: `mailto:hi@fusia.kwezal.com?subject=${localStr.subjectSuggest}`,
      },
      {
        icon: <TranslateIcon color={LIST_ITEM_COLOR} />,
        text: localStr.translate,
        href: `mailto:contribute@fusia.kwezal.com?subject=${localStr.subjectTranslate}`,
      },
      {
        icon: <FavoriteIcon color={LIST_ITEM_COLOR} />,
        text: localStr.offerHelp,
        href: `mailto:contribute@fusia.kwezal.com?subject=${localStr.subjectOfferHelp}`,
      },
    ];

    return (
      <div>
        <Dialog open={this.props.open}>
          <DialogTitle>{localStr.dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText color="textPrimary">
              {localStr.dialogText}
            </DialogContentText>
            <List>
              {listItems.map((item, i) => this.createListItem(item, i))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={() => this.props.onClose()}>
              {str.close}
            </Button>
          </DialogActions>
        </Dialog>
        <ShareMenu
          anchor={this.state.shareMenuAnchor}
          onClose={() => this.closeShareMenu()}
          onStarsChanged={(stars) => this.props.onStarsChanged(stars)}
        />
      </div>
    );
  }

  private createListItem(info: ListItemInfo, index: number) {
    const item = (
      <ListItem
        disabled={info.disabled === true}
        key={index}
        button
        divider
        onClick={
          info.onClick === undefined ? undefined : (e) => info.onClick!(e)
        }
      >
        <ListItemIcon>{info.icon}</ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ color: LIST_ITEM_COLOR }}
          primary={info.text}
        />
      </ListItem>
    );

    if (info.href === undefined) {
      return item;
    } else {
      return (
        <Link
          key={info.href}
          href={info.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item}
        </Link>
      );
    }
  }

  private closeShareMenu() {
    this.setState({ shareMenuAnchor: null });
  }
}
