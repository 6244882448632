import { Component } from "react";
import React from "react";
import {
  DialogTitle,
  ListItemText,
  ListItemSecondaryAction,
  DialogActions,
  Button,
  Dialog,
  List,
  ListItem,
} from "@material-ui/core";
import str from "../../StringResources";
import Player from "../../game/Player";
import ShareMenu from "../menus/ShareMenu";
import LocalizedStrings from "react-localization";
import BuyExperienceMenu from "../menus/BuyExperienceMenu";

const localStr = new LocalizedStrings({
  en: {
    getExperience: `Get experience`,
    getStars: "Get stars",
  },
  pl: {
    getExperience: `Zdobądź doświadczenie`,
    getStars: "Zdobądź gwiazdki",
  },
});

interface Props {
  open: boolean;
  playerName: string;
  experience: number;
  stars: number;
  onClose: () => void;
  onStarsChanged: (stars: number) => void;
  onExperienceChanged: (experience: number) => void;
}

interface State {
  shareMenuAnchor: HTMLElement | null;
  buyExperienceMenuAnchor: HTMLElement | null;
}

export default class PlayerCardDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { shareMenuAnchor: null, buyExperienceMenuAnchor: null };
  }
  render() {
    return (
      <div>
        <Dialog open={this.props.open} fullWidth>
          <DialogTitle>{this.props.playerName}</DialogTitle>
          <List dense>
            <ListItem>
              <ListItemText className="padding-end" primary={str.level} />
              <ListItemSecondaryAction>
                {Player.getLevel(this.props.experience)}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText className="padding-end" primary={str.experience} />
              <ListItemSecondaryAction>
                {this.props.experience}/
                {Player.getRequiredExperience(
                  Player.getLevel(this.props.experience) + 1
                )}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText className="padding-end" primary={str.stars} />
              <ListItemSecondaryAction>
                {this.props.stars}
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <DialogActions>
            <Button
              color="primary"
              onClick={(e) =>
                this.setState({ buyExperienceMenuAnchor: e.currentTarget })
              }
            >
              {localStr.getExperience}
            </Button>
            <Button
              color="primary"
              onClick={(e) =>
                this.setState({ shareMenuAnchor: e.currentTarget })
              }
            >
              {localStr.getStars}
            </Button>
            <Button color="default" onClick={this.props.onClose}>
              {str.close}
            </Button>
          </DialogActions>
        </Dialog>
        <ShareMenu
          anchor={this.state.shareMenuAnchor}
          onClose={() => this.closeShareMenu()}
          onStarsChanged={(stars) => this.props.onStarsChanged(stars)}
        />
        <BuyExperienceMenu
          anchor={this.state.buyExperienceMenuAnchor}
          stars={this.props.stars}
          onClose={() => this.closeBuyExperienceMenu()}
          onStarsChanged={(stars) => this.props.onStarsChanged(stars)}
          onExperienceChanged={(experience) =>
            this.props.onExperienceChanged(experience)
          }
        />
      </div>
    );
  }

  private closeShareMenu() {
    this.setState({ shareMenuAnchor: null });
  }

  private closeBuyExperienceMenu() {
    this.setState({ buyExperienceMenuAnchor: null });
  }
}
