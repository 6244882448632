export default class Stats {
  primaryText: string;
  readonly secondaryText: string;
  readonly key: string;
  value?: number;

  constructor(
    key: string,
    primaryText: string,
    secondaryText: string,
    value?: number
  ) {
    this.primaryText = primaryText;
    this.secondaryText = secondaryText;
    this.key = key;
    this.value = value;
  }
}
