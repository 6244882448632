import {
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  CardHeader,
  Avatar,
} from "@material-ui/core";
import React, { Component } from "react";
import LocalizedStrings from "react-localization";
import str from "../../StringResources";

const SUBHEADER_COLOR = "primary";

const localStr = new LocalizedStrings({
  en: {
    photos: "Photos",
    graphics: "Graphics",
    specialThanks: "Special thanks",
    thanksMaterialUiSecondary: "For creating a brilliant front-end library",
  },
  pl: {
    photos: "Zdjęcia",
    graphics: "Grafika",
    specialThanks: "Szczególne podziękowania",
    thanksMaterialUiSecondary:
      "Za stworzenie genialnej biblioteki front-endowej",
  },
});

type ListItemInfo = {
  primary: string;
  secondary?: string;
};

interface Props {
  open: boolean;
  onClose: () => void;
}

interface State {}

export default class AuthorsDialog extends Component<Props, State> {
  render() {
    const graphicsAuthors: ListItemInfo[] = [
      { primary: "Kwezal" },
      { primary: "pch.vector" },
      { primary: "Freepik" },
    ];

    const photosAuthors: ListItemInfo[] = [
      { primary: "Jess Bailey" },
      { primary: "Rafael Cerqueira" },
      { primary: "Jonatan Pie" },
    ];

    const musicAuthors: ListItemInfo[] = [{ primary: "Benjamin Tissot" }];

    const specialThanks: ListItemInfo[] = [
      {
        primary: "Material-UI",
        secondary: localStr.thanksMaterialUiSecondary,
      },
    ];

    return (
      <Dialog open={this.props.open} fullWidth>
        <DialogTitle>{str.authors}</DialogTitle>
        <DialogContent>
          {this.createKwezalCard()}
          <List>
            <ListSubheader color={SUBHEADER_COLOR} disableSticky>
              {localStr.graphics}
            </ListSubheader>
            {graphicsAuthors.map((item, i) => this.createListItem(i, item))}
            <ListSubheader color={SUBHEADER_COLOR} disableSticky>
              {localStr.photos}
            </ListSubheader>
            {photosAuthors.map((item, i) => this.createListItem(i, item))}
            <ListSubheader color={SUBHEADER_COLOR} disableSticky>
              {str.music}
            </ListSubheader>
            {musicAuthors.map((item, i) => this.createListItem(i, item))}
            <ListSubheader color={SUBHEADER_COLOR} disableSticky>
              {localStr.specialThanks}
            </ListSubheader>
            {specialThanks.map((item, i) => this.createListItem(i, item))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => this.props.onClose()}>
            {str.close}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private createKwezalCard() {
    return (
      <Card>
        <CardActionArea
          onClick={() => window.open("https://kwezal.com", "_blank")}
        >
          <CardMedia image="/graphics/kwezal-banner.png" />
          <CardHeader
            title="Kwezal"
            titleTypographyProps={{
              color: "primary",
              style: { fontWeight: "bold" },
            }}
            subheader="Time for Freedom and Creativity"
            subheaderTypographyProps={{ color: "secondary" }}
            avatar={
              <Avatar alt={str.kwezalIcon} src="/graphics/ui/kwezal-logo.png" />
            }
          />
        </CardActionArea>
      </Card>
    );
  }

  private createListItem(index: number, info: ListItemInfo) {
    return (
      <ListItem key={index} dense>
        <ListItemText primary={info.primary} secondary={info.secondary} />
      </ListItem>
    );
  }
}
