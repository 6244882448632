import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { sprintf } from "sprintf-js";
import KVDb from "../db/KVDb";
import { MovesEntry, PointsEntry, RoundsEntry } from "../db/KVDbEntries";
import Controls from "../game/Controls";
import Game from "../game/Game";
import { Direction } from "../game/GameBoard";
import GameConfig from "../game/GameConfig";
import { PaintItWhiteMode } from "../game/GameMode";
import { renderGameBoard } from "../game/GameViewsRenderer";
import str from "../StringResources";
import { STAR_COLOR } from "../theme/Theme";
import { finishComponent } from "../Utils";
import "./styles/GameComponent.css";

const INFO_TYPOGRAPHY_VARIANT = "inherit";
const SUMMARY_INFO_TYPOGRAPHY_VARIANT = "h6";

const MIN_SWIPE = 100;

interface Props extends RouteComponentProps {
  game: Game;
  onStarsAdded: (stars: number) => void;
  onStarsTaken: (stars: number) => void;
  onExperienceAdded: (exp: number) => void;
  onUnmount: () => void;
}

interface State {
  game: Game;
  gameFinished: boolean;
}

export default class GameComponent extends Component<Props, State> {
  private static controls: Controls;
  private static onUnload = (e: any) => {
    (e as any).returnValue = str.gameLeaveConfirmation; // TODO: confirmation when go back by button
  };

  swipeX = 0;
  swipeY = 0;

  constructor(props: Props) {
    super(props);

    this.state = {
      game: this.props.game || new Game(new GameConfig(new PaintItWhiteMode())),
      gameFinished: false,
    };

    // For demo
    /* this.state.game.board.fields.forEach((row) => {
      row.forEach((cell) => {
        cell.colorHolder = new ColorHolder(
          cell.y * row.length + cell.x,
          Color.values[(cell.y * row.length + cell.x) % 8],
          this.state.game.config
        );
      });
    }); */

    if (this.props.game === undefined) {
      finishComponent(this.props);
    }
  }

  componentDidMount() {
    if (this.props.game !== undefined) {
      this.state.game.onGameEnd = () => {
        this.onGameEnd();
      };
      this.state.game.onStateChanged = (state) => {
        this.setState({ game: state });
      };

      GameComponent.controls = new Controls(this.state.game);
      GameComponent.controls.attach();
      this.state.game.start();

      window.addEventListener("beforeunload", GameComponent.onUnload);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", GameComponent.onUnload);

    if (GameComponent.controls !== undefined) {
      GameComponent.controls.detach();
    }

    this.props.onUnmount();
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return newProps.game !== this.props.game || newState !== this.state;
  }

  render() {
    return (
      <div
        id="main-container"
        onTouchMove={(e) => {
          e.preventDefault();
          return false;
        }}
        onMouseDown={(e) => {
          this.startSwipeDetection(e.clientX, e.clientY);
        }}
        onTouchStart={(e) => {
          this.startSwipeDetection(
            e.changedTouches[0].clientX,
            e.changedTouches[0].clientY
          );
        }}
        onMouseUp={(e) => {
          this.endSwipeDetection(e.clientX, e.clientY);
        }}
        onTouchEnd={(e) => {
          this.endSwipeDetection(
            e.changedTouches[0].clientX,
            e.changedTouches[0].clientY
          );
        }}
      >
        <div id="info-container">
          <Box component="span" mx={2}>
            <Typography
              variant={INFO_TYPOGRAPHY_VARIANT}
              className="fantasy-font"
            >
              {str.points}
            </Typography>
          </Box>
          <Typography
            id="points"
            variant={INFO_TYPOGRAPHY_VARIANT}
            className="fantasy-font"
            color="primary"
          >
            0
          </Typography>
          <Box component="span" id="game-moves-container">
            <Typography
              id="moves"
              variant={INFO_TYPOGRAPHY_VARIANT}
              className="fantasy-font"
              color="primary"
            >
              0
            </Typography>
            <Box component="span" mx={2}>
              <Typography
                variant={INFO_TYPOGRAPHY_VARIANT}
                className="fantasy-font"
              >
                {str.moves}
              </Typography>
            </Box>
          </Box>
        </div>
        <div id="game-board-container">
          <div id="game-board">
            <div className="texture">
              <div id="fields-table">
                {renderGameBoard(this.state.game.board)}
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.gameFinished}
          onClose={() => this.onModalClose()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.gameFinished}>
            <div id="game-over-modal-container">
              <Paper id="game-over-modal" elevation={1}>
                <div className="text-center">
                  {this.generateStars(this.state.game.stars)}
                </div>
                <Typography variant="h4" className="text-center">
                  {str.getString(
                    `summaryPrimary${sprintf(
                      "%02d",
                      this.state.game.stars * 10
                    )}`
                  )}
                </Typography>

                <div className="text-center">
                  {str.getString(
                    `summarySecondary${sprintf(
                      "%02d",
                      this.state.game.stars * 10
                    )}`
                  )}
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <Typography variant={SUMMARY_INFO_TYPOGRAPHY_VARIANT}>
                          {str.points}
                        </Typography>
                      </td>
                      <td>
                        <Typography
                          id="points"
                          variant={SUMMARY_INFO_TYPOGRAPHY_VARIANT}
                          color="primary"
                        >
                          {this.state.game.points}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography variant={SUMMARY_INFO_TYPOGRAPHY_VARIANT}>
                          {str.moves}
                        </Typography>
                      </td>
                      <td>
                        <Typography
                          id="moves"
                          variant={SUMMARY_INFO_TYPOGRAPHY_VARIANT}
                          color="primary"
                        >
                          {this.state.game.moves}
                        </Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    this.onModalClose();
                  }}
                >
                  {str.getString(
                    `finishGame${sprintf("%02d", this.state.game.stars * 10)}`
                  )}
                </Button>
              </Paper>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }

  private generateStars(n: number) {
    const stars = [];
    for (let i = 1; i <= n; ++i) {
      stars.push(<StarIcon key={`star-${i}`} htmlColor={STAR_COLOR} />);
    }
    if ((n * 2) % 2 === 1) {
      stars.push(<StarHalfIcon key={`star-${n}`} htmlColor={STAR_COLOR} />);
    }
    for (let i = Math.ceil(n); i < 5; ++i) {
      stars.push(<StarBorderIcon key={`star-${i}`} htmlColor={STAR_COLOR} />);
    }

    return stars;
  }

  private onModalClose() {
    this.props.onStarsAdded(this.getGainedStarsNumber());
    this.props.onExperienceAdded(Math.round(this.state.game.stars * 2));
    this.setState({ gameFinished: false });
    finishComponent(this.props);
  }

  private onGameEnd() {
    KVDb.open()
      .then((db) => {
        db.add(RoundsEntry(), 1);
        db.add(PointsEntry(), this.state.game.points);
        db.add(MovesEntry(), this.state.game.moves);
      })
      .finally(() => KVDb.close());

    this.setState({ gameFinished: true });
  }

  private getGainedStarsNumber() {
    return Math.floor(this.state.game.stars);
  }

  private startSwipeDetection(x: number, y: number) {
    this.swipeX = x;
    this.swipeY = y;
  }

  private endSwipeDetection(x: number, y: number) {
    const xDiff = x - this.swipeX;
    const xMappedDiff = Math.abs(xDiff) - MIN_SWIPE;
    const yDiff = y - this.swipeY;
    const yMappedDiff = Math.abs(yDiff) - MIN_SWIPE;

    if (xMappedDiff < 0 && yMappedDiff < 0) return;

    let direction: Direction = Direction.LEFT;
    if (xMappedDiff > yMappedDiff) {
      direction = xDiff > 0 ? Direction.RIGHT : Direction.LEFT;
    } else if (yMappedDiff > 0) {
      direction = yDiff > 0 ? Direction.DOWN : Direction.UP;
    }

    this.state.game.move(direction);
  }
}
