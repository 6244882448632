import LocalizedStrings from "react-localization";

const str = new LocalizedStrings({
  en: {
    adsAboutButton: "Learn more about in-app ads",
    adsAboutText0:
      "I know, I know. Ads. But hey, my creator spent a lot of time thinking, designing and programming me. Moreover, he keeps adding new things and improving the old ones so you, the player, won't get bored and have a nice time with me. These ads help him to keep going. Please, also note that all of these ads are non intrusive banners. He tried hard to find a decent consensus and I think he succeded.",
    adsAboutText1:
      "Oh, one more thing. For my creator, freedom and privacy are very important values in life and hence he is an opponent of surveillance. You don't have to worry about your data. All the ads are configured so they cannot gather or use any information about you. You're welcome.",
    adsAboutTitle: "About ads",
    adsAltText:
      "Consider unblocking ads for Fusia. They keep her free. You don't want to imprison her, do you? 😉",
    adsUnblockButton: "Whitelist me in ad blocker",
    adsUnblockItem0: "On the toolbar, click the icon of your ad blocker",
    adsUnblockItem1:
      "Click switch or menu item to disable blocking ads on fusia.kwezal.com website",
    adsUnblockItem2: "Now the icon of your ad blocker should turn gray",
    adsUnblockItem3: "Click refresh or continue button to apply changes",
    adsUnblockItem4:
      "Continue having a nice and relaxing time as the ads are not intrusive 😊",
    adsUnblockText:
      "In order to whitelist Fusia, please follow these steps when the app is in the selected browser tab:",
    adsUnblockTitle: "Unblock ads",
    appName: "Fusia",
    authors: "Authors",
    buy: "Buy",
    close: "Close",
    collectStars: "Collect stars ⭐",
    comingSoon: "Coming soon",
    error: "Error",
    errorVerificationText:
      "The provided URL does not point to a valid post sharing Fusia 😟",
    exit: "Exit",
    experience: "Experience",
    finishGame00: "I'm going to cry",
    finishGame05: "This star is half full",
    finishGame10: "At least there is one star",
    finishGame15: "I have to play more",
    finishGame20: "Well",
    finishGame25: "Bull's eye! Oh, wait…",
    finishGame30: "Nice!",
    finishGame35: "Great!",
    finishGame40: "Awesome!",
    finishGame45: "Excellent!",
    finishGame50: "I'm a freaking genius!",
    firstName: "Name",
    formatCopiedToClipboard: "%s copied to clipboard.",
    formatLevelRequired: "Level %d required",
    formatLevelUp: "Congratulations! You promoted to level %d!",
    formatStarsGainedDialogText:
      "You've gained %d pretty, precious, polished stars!",
    formatVerificationDialogText:
      "You're only one step from getting bonus stars! To get your gift of %d⭐, paste the web address of the shared post to the text field below:",
    formatWelcomeGiftDialogText:
      "CONGRATULATIONS!!! You are the 1,000,000th player today!… Okay, I'm kidding. But here, take %d⭐ as my welcome gift. 😊",
    gameLeaveConfirmation:
      "Are you sure to leave the game? Your progress won't be saved.",
    gameModeLockedDescription:
      "Only to find out that the creator's programming skills are awesome as well.",
    gameModeLockedName:
      "When you use your awesome programming skills to unblur the text",
    gameModes: "Game modes",
    great: "Great",
    introductionDialogText:
      "Hello there! I'm so glad to see a new face here! 🥰 As you probably already know, I'm Fusia, a very colorful and relaxing game. And what's your name if you don't mind me asking?",
    introductionDialogTitle: "Introduce yourself",
    kwezalIcon: "Kwezal logo",
    leaveGame: "Leave game",
    level: "Level",
    levelUp: "Level up",
    levelUpUndoLimitIncreased: "The undo limit increased",
    levelUpUnlockedGameMode: "New game mode unlocked",
    loading: "Loading",
    moves: "Moves",
    music: "Music",
    nameAristotle: "Aristotle",
    nameDemocritus: "Democritus",
    nameEpicurus: "Epicurus",
    nameEuclid: "Euclid",
    namePlato: "Plato",
    namePlutarch: "Plutarch",
    namePtolemy: "Claudius Ptolemy",
    nameVitello: "Vitello",
    play: "Play",
    player: "Player",
    points: "Points",
    random_verb: "Random",
    resign: "Resign",
    save: "Save",
    saveAndExit: "Save and exit",
    settings: "Settings",
    share: "Share",
    sharedPostUrl: "URL of shared post",
    shareFacebook: "Share on Facebook",
    shareReddit: "Share on Reddit",
    shareVKontakte: "Share on VK",
    signInWithGoogle: "Sign in with Google",
    stars: "Stars",
    starsGainedDialogTitle: "You received stars!",
    stats: "Stats",
    statsFriendsPrimary: "Friends",
    statsFriendsSecondary: "Number of invited friends",
    statsMovesPrimary: "Moves",
    statsMovesSecondary: "Total number of moves",
    statsPointsPrimary: "Points",
    statsPointsSecondary: "Total number of gained points",
    statsRoundsPrimary: "Rounds",
    statsRoundsSecondary: "Number of played rounds",
    statsStarsGainedPrimary: "Stars",
    statsStarsGainedSecondary: "Total number of gained stars",
    stay: "Stay",
    summaryPrimary00: "Erm…",
    summaryPrimary05: "Partially fine",
    summaryPrimary10: "Not bad",
    summaryPrimary15: "Fine",
    summaryPrimary20: "Well",
    summaryPrimary25: "Good",
    summaryPrimary30: "Very good",
    summaryPrimary35: "Splendid",
    summaryPrimary40: "Gorgeous!",
    summaryPrimary45: "Magnificent!",
    summaryPrimary50: "Flawless Victory!",
    summarySecondary00:
      "Am I joke to you? What the heck was that?! I always try to see good things in players, but you just humiliated me!",
    summarySecondary05:
      "I tried hard to give you at least one star, but that result… You made me sad… Please, let's see each other more often so you can play better.",
    summarySecondary10:
      "I gave you one star so you won't be sad, but please, play more to get better results.",
    summarySecondary15: "Hm. You know what will help you to get more stars?",
    summarySecondary20:
      "Well… We should see each other more often. That's the only way to improve your skills.",
    summarySecondary25:
      "Right in the middle! Keep up the good work and next time I'll give you more stars. 😉",
    summarySecondary30: "Well played! I see potential in you.",
    summarySecondary35:
      "Very interesting and pretty game! I'm happy we've met each other!",
    summarySecondary40: "It was an honor to see you in action.",
    summarySecondary45:
      "I'm touched… You don't see such a beautiful game every day.",
    summarySecondary50: "You're my very favorite player, you know? 💜",
    tweet: "Tweet",
    undo: "Undo",
    verification: "Verification",
    verify: "Verify",
    welcome: "Welcome",
    welcomeGiftDialogTitle: "Welcome gift",
  },
  pl: {
    adsAboutButton: "Dowiedz się więcej o reklamach w aplikacji",
    adsAboutText0:
      "Wiem, wiem. Reklamy. Ale hej, mój twórca spędził sporo czasu na mysleniu, projektowaniu i programowaniu mnie. Ponadto wciąż dodaje nowe rzeczy i ulepsza stare tak, abyś Ty, graczu, nie nudził się i spędzał ze mną miły czas. Reklamy pozwalają mu kontynuować tę pracę. Proszę, zwróć też uwagę, że wszystkie z tych reklam to nieinwazyjne banery. Mocno się starał, aby znaleźć złoty środek i myślę że mu się to udało.",
    adsAboutText1:
      "Ach, jeszcze jedna rzecz. Dla mojego twórcy, wolność i prywatność to bardzo ważne wartości w życiu, dlatego jest przeciwnikiem inwigilacji. Nie musisz się martwić o swoje dane. Wszystkie reklamy są skonfigurowane tak, aby nie mogły gromadzić i używać żadnych informacji o Tobie. Nie ma za co.",
    adsAboutTitle: "O reklamach",
    adsAltText:
      "Rozważ odblokowanie reklam dla Fusi. To one sprawiają, że jest wolna. Nie chcesz jej chyba uwięzić, prawda? 😉",
    adsUnblockButton: "Dodanj mnie na białą listę w blokerze reklam",
    adsUnblockItem0: "Kliknij ikonę Twojego blokera reklam na pasku narzędzi",
    adsUnblockItem1:
      "Kliknij przełącznik lub opcję w menu aby wyłączyć blokowanie reklam na stronie fusia.kwezal.com",
    adsUnblockItem2: "Teraz ikona blokera reklam powinna stać się szara",
    adsUnblockItem3:
      "Kliknij przycisk odświeżenia lub kontynuacji aby zastosować zmiany",
    adsUnblockItem4:
      "Kontynuuj miły i relaksujący czas z grą, jako że reklamy nie są inwazyjne 😊",
    adsUnblockText:
      "Aby dodać Fusię na białą listę, proszę postępuj zgodnie z poniższymi krokami kiedy aplikacja jest w aktywnej zakładce przeglądarki:",
    adsUnblockTitle: "Odblokuj reklamy",
    appName: "Fusia",
    authors: "Autorzy",
    buy: "Kup",
    close: "Zamknij",
    collectStars: "Odbierz gwiazdki ⭐",
    comingSoon: "Już wkrótce",
    error: "Błąd",
    errorVerificationText:
      "Podany adres URL nie wskazuje na prawidłowy wpis udostępniający Fusię 😟",
    exit: "Wyjście",
    experience: "Doświadczenie",
    finishGame00: "Idę się popłakać",
    finishGame05: "Ta gwiazdka jest w połowie pełna",
    finishGame10: "Przynajmniej jest jedna gwiazdka",
    finishGame15: "Muszę grać więcej",
    finishGame20: "Cóż",
    finishGame25: "Bull's eye! A nie, czekaj…",
    finishGame30: "Nieźle!",
    finishGame35: "Świetnie!",
    finishGame40: "Niesamowicie!",
    finishGame45: "Znakomicie!",
    finishGame50: "Jestem geniuszem!",
    firstName: "Imie",
    formatCopiedToClipboard: "Skopiowano %s do schowka.",
    formatLevelRequired: "Wymagany poziom %d",
    formatLevelUp: "Gratulacje! Zdobyto %d poziom!",
    formatStarsGainedDialogText:
      "Zyskano %d ładnych, cennych, błyszczących gwiazdek!",
    formatVerificationDialogText:
      "Jeszcze tylko krok dzieli Cię od bonusowych gwiazdek! Aby otrzymać podarunek w postaci %d⭐, wklej adres udostępnionego wpisu w pole poniżej:",
    formatWelcomeGiftDialogText:
      "GRATULACJE!!! Jesteś 1,000,000 graczem dzisiaj!… Dobra, żartuję. Ale tutaj, trzymaj %d⭐ jako mój prezent powitalny. 😊",
    gameLeaveConfirmation:
      "Na pewno chcesz opuścić grę? Postęp nie zostanie zapisany.",
    gameModeLockedDescription:
      "Tylko po to, aby dowiedzieć się, że zdolności programistyczne twórcy także są niesamowite.",
    gameModeLockedName:
      "Kiedy używasz swoich niesamowitych zdolności programistycznych aby usunąć rozmycie tekstu",
    gameModes: "Tryby rozgrywki",
    great: "Świetnie",
    introductionDialogText:
      "Cześć! Tak się cieszę, że widzę tutaj nową twarz 🥰! Pewnie już to wiesz, ale i tak się przedstawię. Ja nazywam się Fusia i jestem bardzo kolorową i relaksującą grą. A jak Ty masz na imię, jeśli wolno spytać?",
    introductionDialogTitle: "Przedstaw się",
    kwezalIcon: "Logo Kwezala",
    leaveGame: "Opuść grę",
    level: "Poziom",
    levelUp: "Awans",
    levelUpUndoLimitIncreased: "Limit cofnięć ruchów zwiększa się",
    levelUpUnlockedGameMode: "Odblokowano nowy tryb rozgrywki",
    loading: "Ładowanie",
    moves: "Ruchy",
    music: "Muzyka",
    nameAristotle: "Arystoteles",
    nameDemocritus: "Demokryt z Abdery",
    nameEpicurus: "Epikur",
    nameEuclid: "Euklides z Aleksandrii",
    namePlato: "Platon",
    namePlutarch: "Plutarch z Cheronei",
    namePtolemy: "Klaudiusz Ptolemeusz",
    nameVitello: "Witelon",
    play: "Graj",
    player: "Gracz",
    points: "Punkty",
    random_verb: "Losuj",
    resign: "Zrezygnuj",
    save: "Zapisz",
    saveAndExit: "Zapisz i wyjdź",
    settings: "Ustawienia",
    share: "Udostępnij",
    sharedPostUrl: "URL udostępnionego wpisu",
    shareFacebook: "Udostępnij na Facebooku",
    shareReddit: "Udostępnij na Reddicie",
    shareVKontakte: "Udostępnij na VK",
    signInWithGoogle: "Zaloguj przez Google",
    stars: "Gwiazdki",
    starsGainedDialogTitle: "Otrzymano gwiazdki!",
    stats: "Statystyki",
    statsFriendsPrimary: "Znajomi",
    statsFriendsSecondary: "Liczba zaproszonych znajomych",
    statsMovesPrimary: "Ruchy",
    statsMovesSecondary: "Całkowita liczba ruchów",
    statsPointsPrimary: "Punkty",
    statsPointsSecondary: "Całkowita liczba zdobytych punktów",
    statsRoundsPrimary: "Rundy",
    statsRoundsSecondary: "Ilość rozegranych rund",
    statsStarsGainedPrimary: "Gwiazdki",
    statsStarsGainedSecondary: "Całkowita liczba zdobytych gwiazdek",
    stay: "Zostań",
    summaryPrimary00: "Ekhem…",
    summaryPrimary05: "Częściowo nieźle",
    summaryPrimary10: "Może być",
    summaryPrimary15: "Nieźle",
    summaryPrimary20: "Cóż",
    summaryPrimary25: "Dobrze",
    summaryPrimary30: "Bardzo dobrze",
    summaryPrimary35: "Wspaniale",
    summaryPrimary40: "Przepięknie!",
    summaryPrimary45: "Fantastycznie!",
    summaryPrimary50: "Bezbłędne zwycięstwo!",
    summarySecondary00:
      "Jestem dla Ciebie żartem? Co to u licha było?! Zawsze staram się dostrzec dobro w graczach, ale właśnie zostałam przez Ciebie upokorzona!",
    summarySecondary05:
      "Bardzo starałam się dać Ci chociaż jedną gwiazdkę, ale ten wynik… Jestem smutna… Proszę, spotykajmy się częściej, żeby Twoja gra była lepsza.",
    summarySecondary10:
      "Dałam Ci jedną gwiazdkę, żeby nie było Ci smutno, ale proszę, graj więcej żeby uzyskiwać lepsze rezultaty.",
    summarySecondary15: "Hm. Wiesz co pomoże Ci zdobyć więcej gwiazdek?",
    summarySecondary20:
      "Cóż… Powinniśmy się spotykać częśćiej. To jedyny sposób, żeby poprawić Twoje umiejętności.",
    summarySecondary25:
      "Prosto w środek! Tak trzymaj, a następnym razem dam Ci więcej gwiazdek. 😉",
    summarySecondary30: "Ładnie zagrane! Widzę w Tobie potencjał.",
    summarySecondary35:
      "Niezwykle ciekawa i ładna gra! Bardzo się cieszę, że się poznaliśmy!",
    summarySecondary40: "To był zaszczyt, móc widzieć Cię w akcji.",
    summarySecondary45:
      "Jestem wzruszona… Nie codziennie widzi się taką piękną grę.",
    summarySecondary50: "Jesteś moim ulubionym graczem, wiesz? 💜",
    tweet: "Tweetnij",
    undo: "Cofnij",
    verification: "Weryfikacja",
    verify: "Zweryfikuj",
    welcome: "Witaj",
    welcomeGiftDialogTitle: "Prezent powitalny",
  },
});

export default str;
