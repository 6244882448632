import { getRandomElement, trimToRange } from "../Utils";
import ColorHolder, { Color } from "./ColorHolder";
import GameConfig from "./GameConfig";
import GameMode from "./GameMode";
import Move, { ChangeType, ColorHolderChange } from "./Move";

export enum Direction {
  LEFT,
  UP,
  RIGHT,
  DOWN,
}

enum AttackResult {
  CONTINUE,
  BLOCK,
  FUSION,
}

const SUMMON_LOOP_LENGTH = 4;

export default class GameBoard {
  readonly config: GameConfig;
  readonly fields: GameBoardField[][];
  private lastHolderId = 0;

  nextColorIndex = 0;

  constructor(config: GameConfig) {
    this.config = config;
    const size = config.boardSize; // TODO remove this field
    this.fields = [];
    for (let x = 0; x < size; ++x) {
      this.fields.push([]);
      for (let y = 0; y < size; ++y) {
        this.fields[x].push(new GameBoardField(x, y));
      }
    }
  }

  summonColorHolder() {
    const color = this.getColorByIndex(this.nextColorIndex);

    const holder = new ColorHolder(this.lastHolderId++, color, this.config);
    this.nextColorIndex = (this.nextColorIndex + 1) % SUMMON_LOOP_LENGTH;
    const field = this.getRandomEmptyField();

    if (field === undefined) {
      console.error("No empty field");
      return undefined;
    } else {
      this.createColorHolder(holder, field);
      return field;
    }
  }

  moveColorHolders(direction: Direction): Move | undefined {
    console.log(`moveColorHolders(${direction})`);

    const size = this.config.boardSize;
    const start =
      direction === Direction.LEFT || direction === Direction.UP ? 0 : size - 1;

    const fieldGetter =
      direction === Direction.LEFT || direction === Direction.RIGHT
        ? (d1: number, d2: number) => this.fields[d2][d1]
        : (d1: number, d2: number) => this.fields[d1][d2];

    const inc = start === 0 ? 1 : -1;
    const offset = start + inc;

    const colorHolderChanges: ColorHolderChange[] = [];
    let somethingMoved = false;

    for (let d1 = 0; d1 < size; ++d1) {
      let hasCollided = false;

      secondDimensionLoop: for (
        let d2 = offset;
        0 <= d2 && d2 < size;
        d2 += inc
      ) {
        const attackerField = fieldGetter(d1, d2);
        if (attackerField.isEmpty()) continue;

        const attackerHolder = attackerField.colorHolder!;

        const hasLimitedDistance = attackerHolder.hasLimitedDistance();
        const collisionFrom = hasLimitedDistance
          ? Math.max(0, d2 - attackerHolder.maxDistance)
          : 0;
        const collisionTo = hasLimitedDistance
          ? Math.min(size - 1, d2 + attackerHolder.maxDistance)
          : size - 1;

        collisionLoop: for (
          let i = d2 - inc;
          collisionFrom <= i && i <= collisionTo;
          i -= inc
        ) {
          const defenderField = fieldGetter(d1, i);
          const [attackResult, newColor] = attackerField.attack(
            defenderField,
            this.config.gameMode
          );

          switch (attackResult) {
            case AttackResult.FUSION: {
              // Only 1 fusion per move allowed
              if (!hasCollided) {
                colorHolderChanges.push(
                  new ColorHolderChange(
                    defenderField.colorHolder!,
                    ChangeType.DISAPPEAR,
                    defenderField
                  )
                );
                /* colorHolderChanges.push(
                  new ColorHolderChange(
                    attackerHolder,
                    ChangeType.MOVE,
                    attackerField,
                    defenderField
                  )
                ); */
                colorHolderChanges.push(
                  new ColorHolderChange(
                    attackerHolder,
                    ChangeType.DISAPPEAR,
                    attackerField
                  )
                );

                const newColorHolder = this.moveColorHolder(
                  attackerField,
                  defenderField,
                  newColor
                );

                colorHolderChanges.push(
                  new ColorHolderChange(
                    newColorHolder,
                    ChangeType.APPEAR,
                    defenderField
                  )
                );

                hasCollided = true;
                somethingMoved = true;
                continue secondDimensionLoop;
              }
              // fallthrough
            }
            // eslint-disable-next-line
            case AttackResult.BLOCK: {
              const positionBeforeBlock = i + inc;
              if (positionBeforeBlock !== d2) {
                const destinationField = fieldGetter(d1, positionBeforeBlock);
                colorHolderChanges.push(
                  new ColorHolderChange(
                    attackerHolder,
                    ChangeType.MOVE,
                    attackerField,
                    destinationField
                  )
                );
                this.moveColorHolder(attackerField, destinationField);
              }
              continue secondDimensionLoop;
            }
            case AttackResult.CONTINUE: {
              somethingMoved = true;
              continue collisionLoop;
            }
          }
        }

        // No collision
        const destinationField = fieldGetter(
          d1,
          hasLimitedDistance
            ? trimToRange(d2 - attackerHolder.maxDistance * inc, 0, size - 1)
            : start
        );
        colorHolderChanges.push(
          new ColorHolderChange(
            attackerHolder,
            ChangeType.MOVE,
            attackerField,
            destinationField
          )
        );
        this.moveColorHolder(attackerField, destinationField);
        somethingMoved = true;
      }
    }

    return somethingMoved ? new Move(colorHolderChanges) : undefined;
  }

  undo() {
    --this.nextColorIndex;
    if (this.nextColorIndex < 0) {
      this.nextColorIndex = SUMMON_LOOP_LENGTH - 1;
    }
  }

  clear() {
    this.fields.forEach((cols) => {
      cols.forEach((field) => {
        field.getHtmlElement()!.innerHTML = "";
      });
    });
  }

  moveColorHolder(
    from: GameBoardField,
    to: GameBoardField,
    fusionColor?: Color
  ): ColorHolder {
    console.log(
      `moveColorHolder(${JSON.stringify(from)}, ${JSON.stringify(
        to
      )}, ${JSON.stringify(fusionColor)})`
    );

    const fromHolder = from.colorHolder!;
    const toHolder = to.colorHolder;
    const fromHolderElem = fromHolder.getHtmlElement()!;
    const toHolderElem = toHolder?.getHtmlElement();

    if (fusionColor === undefined) fusionColor = from.colorHolder!.color;

    const transition = `transform var(--color-holder-move-duration),
    opacity var(--color-holder-move-duration)`;
    fromHolderElem.style.transition = transition;
    if (toHolderElem !== undefined && toHolderElem !== null) {
      toHolderElem.style.transition = transition;
    }

    from.replacingColorHolder = null;
    // from.colorHolder = undefined;
    const newColorHolder = new ColorHolder(
      this.lastHolderId++,
      fusionColor,
      this.config
    );
    // to.colorHolder = newColorHolder;
    to.replacingColorHolder = newColorHolder;

    const fromElem = from.getHtmlElement()!;
    const toElem = to.getHtmlElement()!;

    const xDiff = toElem.offsetTop - fromElem.offsetTop;
    const yDiff = toElem.offsetLeft - fromElem.offsetLeft;

    fromHolder.transform = `translate(${yDiff}px,${xDiff}px)`;
    if (fusionColor !== fromHolder.color) {
      fromHolder.opacity = 0;
    }
    if (toHolderElem !== undefined && toHolderElem !== null) {
      toHolder!.opacity = 0;
    }
    // console.log("FROM 1", fromHolderElem);
    // fromHolderElem.style.transform = `translate(${yDiff}px,${xDiff}px)`;
    // if (fusionColor !== fromHolder.color) {
    //   fromHolderElem.style.opacity = "0";
    // }
    // if (toHolderElem !== undefined && toHolderElem !== null) {
    //   toHolderElem!.style.opacity = "0";
    // }

    // setTimeout(() => {
    //   console.log("FROM 2", fromHolder.getHtmlElement());
    //   fromHolderElem.style.transform = "";
    //   fromHolderElem.style.opacity = "";
    //   if (toHolderElem !== null && toHolderElem !== undefined) {
    //     toHolderElem!.style.opacity = "";
    //   }

    //   // from.colorHolder = undefined
    //   // fromElem!.innerHTML = "";
    //   // toElem!.innerHTML = renderColorHolderAsString(to.colorHolder!);
    // }, cssTimeVar(CSS_VAR_COLOR_HOLDER_MOVE_DURATION));

    return newColorHolder;
  }

  replaceColorHolders() {
    this.fields.forEach((row) => {
      row.forEach((field) => {
        if (field.colorHolder !== undefined) {
          const fieldElem = field.colorHolder.getHtmlElement();
          if (fieldElem !== undefined) {
            fieldElem!.style.transition = "none";
          }
        }

        if (field.replacingColorHolder !== undefined) {
          field.colorHolder =
            field.replacingColorHolder === null
              ? undefined
              : field.replacingColorHolder;
          field.replacingColorHolder = undefined;
        }
      });
    });
  }

  remove(field: GameBoardField) {
    field.colorHolder = undefined;
    // field.getHtmlElement()!.innerHTML = "";
  }

  availableMoveExists() {
    if (this.hasEmptyField()) {
      return true;
    }

    // TODO check if really no move

    return false;
  }

  hasEmptyField() {
    for (const cols of this.fields) {
      for (const field of cols) {
        if (field.isEmpty()) {
          return true;
        }
      }
    }

    return false;
  }

  getRandomEmptyField() {
    const emptyFields: GameBoardField[] = [];
    this.fields.forEach((cols) => {
      cols.forEach((field) => {
        if (field.isEmpty()) {
          emptyFields.push(field);
        }
      });
    });

    if (emptyFields.length === 0) return undefined;
    else return getRandomElement(emptyFields);
  }

  createColorHolder(holder: ColorHolder, field: GameBoardField) {
    console.log(
      `createColorHolder(${JSON.stringify(holder)}, ${JSON.stringify(field)})`
    );
    field.colorHolder = holder;

    const fieldElement = field.getHtmlElement();
    // fieldElement!.innerHTML = renderColorHolderAsString(holder);
  }

  private getColorByIndex(index: number) {
    switch (index) {
      case 0:
        return Color.BLACK;
      case 1:
        return Color.RED;
      case 2:
        return Color.GREEN;
      case 3:
        return Color.BLUE;
      default:
        return Color.WHITE;
    }
  }
}

export class GameBoardField {
  x: number;
  y: number;
  colorHolder?: ColorHolder;

  replacingColorHolder?: ColorHolder | null = undefined;

  constructor(x: number, y: number, colorHolder?: ColorHolder) {
    this.x = x;
    this.y = y;
    this.colorHolder = colorHolder;
  }

  attack(other: GameBoardField, gameMode: GameMode): [AttackResult, Color?] {
    const holder =
      other.replacingColorHolder === null
        ? undefined
        : other.replacingColorHolder !== undefined
        ? other.replacingColorHolder
        : other.colorHolder;
    if (holder === undefined) return [AttackResult.CONTINUE, undefined];

    const mixResult = this.mixWith(this.colorHolder!, holder, gameMode);
    if (mixResult !== undefined) {
      gameMode.onFusion(this.colorHolder!, holder, mixResult);
    }

    return [
      mixResult === undefined ? AttackResult.BLOCK : AttackResult.FUSION,
      mixResult,
    ];
  }

  private mixWith(
    holder0: ColorHolder,
    holder1: ColorHolder,
    gameMode: GameMode
  ): Color | undefined {
    return holder0.color.mixWith(holder1.color, gameMode);
  }

  isEmpty() {
    return this.colorHolder === undefined;
  }

  getHtmlElement() {
    return document.getElementById(this.getHtmlElementId());
  }

  getHtmlElementId() {
    return `field-${this.x}-${this.y}`;
  }
}
