import { Avatar, Box, Card, Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import { Component } from "react";
import LocalizedStrings from "react-localization";
import str from "../../StringResources";

const localStr = new LocalizedStrings({
  en: {
    contact: "Contact",
    madeBy: "Made by",
    privacyPolicy: "Privacy policy",
    termsAndConditions: "Terms & conditions",
  },
  pl: {
    contact: "Kontakt",
    madeBy: "Stworzył",
    privacyPolicy: "Polityka prywatności",
    termsAndConditions: "Zasady i warunki",
  },
});

type LinkInfo = {
  href: string;
  text: string;
};

interface Props {}

interface State {}

export default class FooterComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return false;
  }

  render() {
    const links: LinkInfo[] = [
      {
        href: "https://fusia.kwezal.com/privacy",
        text: localStr.privacyPolicy,
      },
      {
        href: "https://fusia.kwezal.com/terms_and_conditions",
        text: localStr.termsAndConditions,
      },
      {
        href: "mailto:hi@fusia.kwezal.com",
        text: localStr.contact,
      },
    ];

    return (
      <Box m={2}>
        <Card>
          <Box p={2}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <Grid item>
                    <Avatar
                      alt={str.kwezalIcon}
                      src="/graphics/ui/kwezal-logo.png"
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      {`${localStr.madeBy} `}
                      <Link href="https://kwezal.com" target="_blank">
                        Kwezal
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {links.map((link, i) => this.createLinkListItem(link, i))}
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    );
  }

  private createLinkListItem(info: LinkInfo, index: number) {
    return (
      <Typography variant="body2" key={index}>
        <Link href={info.href} target="_blank" color="inherit">
          {info.text}
        </Link>
      </Typography>
    );
  }
}
