import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { Component } from "react";
import { PLAYER_NAME_LENGTH_LIMIT } from "../../Config";
import Player from "../../game/Player";
import str from "../../StringResources";
import StarsGainedDialog from "./StarsGainedDialog";

const WELCOME_GIFT_STARS_NUMBER = 10;

interface Props {
  open: boolean;
  onClose: () => void;
  onNameChanged: (name: string) => void;
  onStarsChanged: (stars: number) => void;
}

interface State {
  playerName: string;
  starsDialogOpen: boolean;
}

export default class IntroductionDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { playerName: "", starsDialogOpen: false };
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open && !this.state.starsDialogOpen}
          onEnter={() => this.setName(Player.getRandomName())}
        >
          <DialogTitle>{str.introductionDialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText color="textPrimary">
              {str.introductionDialogText}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label={str.firstName}
              type="text"
              value={this.state.playerName}
              inputProps={{ maxLength: PLAYER_NAME_LENGTH_LIMIT }}
              onInput={(e) => this.setName((e.target as any).value)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              onClick={() =>
                this.setName(Player.getRandomName(this.state.playerName))
              }
            >
              {str.random_verb}
            </Button>
            <Button
              color="primary"
              onClick={() => this.setState({ starsDialogOpen: true })}
            >
              {str.save}
            </Button>
          </DialogActions>
        </Dialog>
        <StarsGainedDialog
          open={this.state.starsDialogOpen}
          starsGained={WELCOME_GIFT_STARS_NUMBER}
          text={str.formatWelcomeGiftDialogText}
          title={str.welcomeGiftDialogTitle}
          onClose={() => this.onClose()}
          onStarsChanged={(stars) => this.props.onStarsChanged(stars)}
        />
      </div>
    );
  }

  private setName(name: string) {
    this.setState({ playerName: name });
    this.props.onNameChanged(name);
  }

  private onClose() {
    this.setState({ starsDialogOpen: false });
    this.props.onClose();
  }
}
