import IKVDb from "./IKVDb";

export default class WebStorageKVDb implements IKVDb {
  private constructor() {}

  static open(): IKVDb {
    return new WebStorageKVDb();
  }

  close() {}

  getAll() {
    const all: { key: string; value: string }[] = [];
    for (let i = 0; i < localStorage.length; ++i) {
      const key = localStorage.key(i)!;
      all.push({ key: key, value: localStorage.getItem(key)! });
    }
    return Promise.resolve(all);
  }

  getOneNumber(key: string) {
    const val = localStorage.getItem(key);
    return Promise.resolve(val !== null ? Number(val) : null);
  }

  setOneNumber(key: string, value: number) {
    return this.setOneString(key, value.toString());
  }

  getOneString(key: string) {
    return Promise.resolve(localStorage.getItem(key));
  }

  setOneString(key: string, value: string) {
    try {
      localStorage.setItem(key, value);
      return Promise.resolve(true);
    } catch (e) {
      console.error(`Could not set value of web storage`);
      console.error(e);
      return Promise.resolve(false);
    }
  }
}
