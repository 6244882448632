import {
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  Paper,
  PropTypes,
  Snackbar,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import GamesIcon from "@material-ui/icons/Games";
import PaletteIcon from "@material-ui/icons/Palette";
import PieChartIcon from "@material-ui/icons/PieChart";
import AppleIcon from "@material-ui/icons/Apple";
import AndroidIcon from "@material-ui/icons/Android";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ROUTE_GAME_MODES, ROUTE_SETTINGS, ROUTE_STATS } from "../Routes";
import str from "../StringResources";
import AdCardComponent from "./core/AdCardComponent";
import ShareMenu from "./menus/ShareMenu";
import GoogleIcon from "./icons/GoogleIcon";
import CloseIcon from "@material-ui/icons/Close";
import FooterComponent from "./core/FooterComponent";
import GoogleSignInDialog from "./dialogs/GoogleSignInDialog";
import { sprintf } from "sprintf-js";
import PeopleIcon from "@material-ui/icons/People";
import LocalizedStrings from "react-localization";
import Platform from "../Platform";
import StarIcon from "@material-ui/icons/Star";
import ContributionDialog from "./dialogs/ContributionDialog";
import AuthorsDialog from "./dialogs/AuthorsDialog";

const IS_IOS_AVAILABLE = false;
const IS_ANDROID_AVAILABLE = Platform.isWeb();

const LOAD_GOOGLE_API_ATTEMPT_LIMIT = 10;
const LOAD_GOOGLE_API_RETRY_INTERVAL = 1000;

const SNACKBAR_DURATION = 10000;

const localStr = new LocalizedStrings({
  en: {
    feedbackAndContribute: "Feedback & contribution",
    formatGoogleSignInFailure: "Google sign in failure. Reason: %s.",
  },
  pl: {
    feedbackAndContribute: "Opinia & wkład",
    formatGoogleSignInFailure: "Błąd logowania na konto Google. Powód: %s.",
  },
});

type NavigationButtonInfo = {
  disabled?: boolean;
  component?: any;
  to?: string;
  className?: string;
  variant?: "text" | "outlined" | "contained";
  color?: PropTypes.Color;
  icon: any;
  text: string;
  onClick?: () => void;
};

type IconButtonInfo = {
  available: boolean;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon: any;
};

interface Props {
  playerName: string;
  onStarsChanged: (stars: number) => void;
}

interface State {
  shareMenuAnchor: HTMLElement | null;
  contributionDialogOpen: boolean;
  googleSignInDialogOpen: boolean;
  authorsDialogOpen: boolean;
  signInSnackbarOpen: boolean;
  signInSnackbarMessage: string;
}

export default class MainMenuComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      shareMenuAnchor: null,
      contributionDialogOpen: false,
      googleSignInDialogOpen: false,
      authorsDialogOpen: false,
      signInSnackbarOpen: false,
      signInSnackbarMessage: "",
    };
  }

  componentDidMount() {
    this.loadGoogleApi();
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return (
      newProps.playerName !== this.props.playerName || newState !== this.state
    );
  }

  render() {
    const navigationButtons: NavigationButtonInfo[] = [
      {
        component: RouterLink,
        to: `/${ROUTE_GAME_MODES}`,
        className: "main-action-button",
        variant: "contained",
        color: "primary",
        icon: <GamesIcon />,
        text: str.play,
      },
      {
        icon: <StarIcon />,
        text: localStr.feedbackAndContribute,
        onClick: () => {
          this.setState({ contributionDialogOpen: true });
        },
      },
      {
        component: RouterLink,
        to: `/${ROUTE_SETTINGS}`,
        icon: <PaletteIcon />,
        text: str.settings,
      },
      {
        component: RouterLink,
        to: `/${ROUTE_STATS}`,
        icon: <PieChartIcon />,
        text: str.stats,
      },
      {
        icon: <SvgIcon>{GoogleIcon}</SvgIcon>,
        text: str.signInWithGoogle,
        onClick: () => {
          this.setState({ googleSignInDialogOpen: true });
        },
      },
      {
        icon: <PeopleIcon />,
        text: str.authors,
        onClick: () => {
          this.setState({ authorsDialogOpen: true });
        },
      },
    ];

    const iconButtons: IconButtonInfo[] = [
      {
        available: true,
        href: undefined,
        onClick: (e) => this.setState({ shareMenuAnchor: e.currentTarget }),
        icon: (
          <Badge badgeContent={`⭐`} color="default">
            <ShareOutlinedIcon fontSize="large" />
          </Badge>
        ),
      },
      {
        available: IS_ANDROID_AVAILABLE,
        href: "https://play.google.com/store/apps/details?id=com.kwezal.fusia",
        icon: (
          <Badge badgeContent={`beta`} color="error">
            <AndroidIcon fontSize="large" />
          </Badge>
        ),
      },
      {
        available: IS_IOS_AVAILABLE,
        href: "",
        icon: <AppleIcon fontSize="large" />,
      },
    ];

    return (
      <div>
        <Box m={2}>
          <Paper elevation={2}>
            <Box p={2}>
              <Typography variant="h3" className="text-center">
                {str.welcome},
              </Typography>
              <Typography
                variant={this.getPlayerNameVariant(`${this.props.playerName}!`)}
                className="text-center"
              >
                {this.props.playerName}!
              </Typography>
              {navigationButtons.map((button) =>
                this.createNavigationButton(button)
              )}
              <span id="google-sign-in" />
              <div className="text-center">
                {iconButtons.map((button, i) =>
                  this.createIconButton(i, button)
                )}
                <ShareMenu
                  anchor={this.state.shareMenuAnchor}
                  onClose={() => this.closeShareMenu()}
                  onStarsChanged={(stars) => this.props.onStarsChanged(stars)}
                />
              </div>
            </Box>
          </Paper>
        </Box>
        <AdCardComponent />
        <FooterComponent />
        <ContributionDialog
          open={this.state.contributionDialogOpen}
          onClose={() => {
            this.setState({ contributionDialogOpen: false });
          }}
          onStarsChanged={(stars) => this.props.onStarsChanged(stars)}
        />
        <GoogleSignInDialog
          open={this.state.googleSignInDialogOpen}
          onResult={(accepted) => {
            this.setState({ googleSignInDialogOpen: false });
            if (accepted) {
              document.getElementById("google-sign-in")?.click();
            }
          }}
        />
        <AuthorsDialog
          open={this.state.authorsDialogOpen}
          onClose={() => {
            this.setState({ authorsDialogOpen: false });
          }}
        />
        <Snackbar
          open={this.state.signInSnackbarOpen}
          autoHideDuration={SNACKBAR_DURATION}
          onClose={() => this.setState({ signInSnackbarOpen: false })}
          message={this.state.signInSnackbarMessage}
          action={
            <IconButton
              size="small"
              color="inherit"
              onClick={() => this.setState({ signInSnackbarOpen: false })}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </div>
    );
  }

  private onSignIn(googleUser: gapi.auth2.GoogleUser) {
    var profile = googleUser.getBasicProfile();
    console.log("ID: " + profile.getId()); // Do not send to your backend! Use an ID token instead.
    console.log("Name: " + profile.getName());
    console.log("Image URL: " + profile.getImageUrl());
    console.log("Email: " + profile.getEmail()); // This is null if the 'email' scope is not present.
  }

  private loadGoogleApi(attemptNumber: number = 1) {
    try {
      gapi.load("auth2", () => {
        // Retrieve the singleton for the GoogleAuth library and set up the client.
        const auth2 = gapi.auth2.init({
          client_id:
            "173305848234-0qorqmftikj8ogrljg8aeo8n5d6oorbn.apps.googleusercontent.com",
          cookie_policy: "single_host_origin",
          // Request scopes in addition to "profile" and "email"
          //scope: "additional_scope"
        });

        const signInButton = document.getElementById("google-sign-in");
        if (signInButton != null) {
          this.attachSignIn(signInButton, auth2);
        }
      });
    } catch (e) {
      console.error(e);
      if (attemptNumber < LOAD_GOOGLE_API_ATTEMPT_LIMIT) {
        setTimeout(() => {
          this.loadGoogleApi(attemptNumber + 1);
        }, LOAD_GOOGLE_API_RETRY_INTERVAL);
      } else {
        console.error(
          `Gave up loading Google API after ${attemptNumber} attempts.`
        );
      }
    }
  }

  private attachSignIn(element: HTMLElement, auth2: gapi.auth2.GoogleAuth) {
    auth2.attachClickHandler(
      element,
      {},
      (googleUser) => {
        console.log("Google onsuccess", googleUser);
        this.onSignIn(googleUser);
      },
      (error) => {
        console.error("Google onfailure");
        this.setState({
          signInSnackbarOpen: true,
          signInSnackbarMessage: sprintf(
            localStr.formatGoogleSignInFailure,
            JSON.stringify(error)
          ),
        });
      }
    );
  }

  private createNavigationButton(info: NavigationButtonInfo) {
    return (
      <Box my={2} key={info.to}>
        <Button
          disabled={info.disabled}
          component={info.component}
          to={info.to}
          className={`start-icon-button${
            info.className !== undefined ? ` ${info.className}` : ""
          }`}
          fullWidth
          variant={info.variant || "outlined"}
          color={info.color || "default"}
          startIcon={info.icon}
          onClick={info.onClick}
        >
          {info.text}
        </Button>
      </Box>
    );
  }

  private createIconButton(index: number, info: IconButtonInfo) {
    if (!info.available) {
      return null;
    }

    const button = (
      <IconButton
        key={index}
        color="secondary"
        onClick={
          info.onClick === undefined ? undefined : (e) => info.onClick!(e)
        }
      >
        {info.icon}
      </IconButton>
    );

    if (info.href === undefined) {
      return button;
    } else {
      return (
        <Link
          key={info.href}
          href={info.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {button}
        </Link>
      );
    }
  }

  private getPlayerNameVariant(name: string) {
    const len = name.length;
    if (len <= 10) {
      return "h3";
    } else if (len <= 15) {
      return "h4";
    } else if (len <= 22) {
      return "h5";
    } else {
      return "h6";
    }
  }

  private closeShareMenu() {
    this.setState({ shareMenuAnchor: null });
  }
}
