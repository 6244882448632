import IKVDb from "./IKVDb";

export interface IKVDbEntry<ValueType> {
  readonly key: string;
  get(db: IKVDb): Promise<ValueType | null>;
  set(db: IKVDb, value: ValueType): Promise<boolean>;
}

export class KVDbNumber implements IKVDbEntry<number> {
  readonly key: string;
  constructor(key: string) {
    this.key = key;
  }

  get(db: IKVDb) {
    return db.getOneNumber(this.key);
  }

  set(db: IKVDb, value: number) {
    return db.setOneNumber(this.key, value);
  }
}

export class KVDbBoolean implements IKVDbEntry<boolean> {
  readonly key: string;
  constructor(key: string) {
    this.key = key;
  }

  get(db: IKVDb) {
    return db.getOneNumber(this.key).then((n) => n !== 0);
  }

  set(db: IKVDb, value: boolean) {
    return db.setOneNumber(this.key, value ? 1 : 0);
  }
}

export class KVDbString implements IKVDbEntry<string> {
  readonly key: string;
  constructor(key: string) {
    this.key = key;
  }

  get(db: IKVDb) {
    return db.getOneString(this.key);
  }

  set(db: IKVDb, value: string) {
    return db.setOneString(this.key, value);
  }
}
