import { createMuiTheme, Theme } from "@material-ui/core";

export class AppTheme {
  private cache?: Theme;
  private readonly creator: () => Theme;

  readonly id: string;
  readonly isDark: boolean;

  constructor(id: string, isDark: boolean, creator: () => Theme) {
    this.id = id;
    this.isDark = isDark;
    this.creator = creator;
  }

  get(allowCache: boolean = true) {
    if (!allowCache || this.cache === undefined) {
      this.cache = this.creator();
    }
    return this.cache;
  }
}

export class AppThemes {
  static readonly FUCHSIA_LIGHT = new AppTheme("fuchsia-light", false, () =>
    createFuchsiaLightTheme()
  );

  static readonly FUCHSIA_DARK = new AppTheme("fuchsia-dark", true, () =>
    createFuchsiaDarkTheme()
  );

  static values = [AppThemes.FUCHSIA_LIGHT, AppThemes.FUCHSIA_DARK];

  static byId(id: string) {
    return (
      AppThemes.values.find((theme) => theme.id === id) ||
      AppThemes.FUCHSIA_LIGHT
    );
  }
}

const createFuchsiaLightTheme = () =>
  createMuiTheme({
    palette: {
      type: "light",
      primary: {
        main: "#E40078",
      },
      secondary: {
        main: "#B53389",
      },
    },
  });

const createFuchsiaDarkTheme = () =>
  createMuiTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#EA80FC",
      },
      secondary: {
        main: "#9575CD",
      },
    },
  });

export const STAR_COLOR = "#f9a825";
