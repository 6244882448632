import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { Component } from "react";
import { sprintf } from "sprintf-js";
import str from "../../StringResources";

interface Props {
  open: boolean;
  starsGained: number;
  text?: string;
  title?: string;
  onClose: () => void;
  onStarsChanged: (stars: number) => void;
}

interface State {
  stars: number; // In state because props can change during closing and information about gaining 0 stars can be visible for user for a moment
}

export default class StarsGainedDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { stars: 0 };
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onEnter={() => this.setState({ stars: this.props.starsGained })}
      >
        <DialogTitle>
          {this.props.title || str.starsGainedDialogTitle}
        </DialogTitle>
        <div className="text-center">
          <img src="/graphics/ui/stars-dialog.png" alt="" />
        </div>
        <DialogContent>
          <DialogContentText color="textPrimary">
            {sprintf(
              this.props.text || str.formatStarsGainedDialogText,
              this.state.stars
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => this.onClose()}>
            {str.collectStars}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private onClose() {
    this.props.onStarsChanged(this.props.starsGained);
    this.props.onClose();
  }
}
