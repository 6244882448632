import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter, HashRouter } from "react-router-dom";
import UiContainerComponent from "./components/UiContainerComponent";
import Platform from "./Platform";
import {
  ROUTE_GAME,
  ROUTE_GAME_MODES,
  ROUTE_SETTINGS,
  ROUTE_STATS,
} from "./Routes";

interface Props {}

export interface State {}

export default class App extends Component<Props, State> {
  render() {
    return this.createRouter(
      <Switch>
        <Route
          exact
          path={[
            `/`,
            `/${ROUTE_GAME_MODES}`,
            `/${ROUTE_GAME}`,
            `/${ROUTE_SETTINGS}`,
            `/${ROUTE_STATS}`,
          ]}
          component={UiContainerComponent}
        />
      </Switch>
    );
  }

  private createRouter(children: any) {
    return Platform.isWeb() ? (
      <BrowserRouter>{children}</BrowserRouter>
    ) : (
      <HashRouter>{children}</HashRouter>
    );
  }
}
