import { getRandomElement, log } from "../Utils";
import str from "../StringResources";

const LEVEL_LOGARITHM_BASE = 1.25;
const LEVEL_MULTIPLIER = 10;

const LEVEL_2_EXPERIENCE_REQUIREMENT = 10;
const EXPERIENCE_REQUIREMENT_OFFSET = 51;
const LEVEL_LOGARITHM_POWER_OFFSET = 6;

export default class Player {
  private constructor() {}

  static getLevel(experience: number) {
    if (experience < LEVEL_2_EXPERIENCE_REQUIREMENT) {
      return 1;
    }

    experience =
      (experience + EXPERIENCE_REQUIREMENT_OFFSET) / LEVEL_MULTIPLIER;
    return Math.max(
      1,
      Math.floor(log(experience, LEVEL_LOGARITHM_BASE)) -
        LEVEL_LOGARITHM_POWER_OFFSET
    );
  }

  static getRequiredExperience(level: number) {
    if (level <= 1) {
      return 0;
    } else if (level === 2) {
      return LEVEL_2_EXPERIENCE_REQUIREMENT;
    }

    return (
      Math.ceil(
        Math.pow(LEVEL_LOGARITHM_BASE, level + LEVEL_LOGARITHM_POWER_OFFSET) *
          LEVEL_MULTIPLIER
      ) - EXPERIENCE_REQUIREMENT_OFFSET
    );
  }

  static getProgress(experience: number) {
    const currLvl = Player.getLevel(experience);
    const reqCurrLvl = Player.getRequiredExperience(currLvl);
    const expDiff = Player.getRequiredExperience(currLvl + 1) - reqCurrLvl;
    return (experience - reqCurrLvl) / expDiff;
  }

  static getRandomName(currentName: string = "") {
    const names = [
      "Al-Kindi",
      "Albert Einstein",
      str.nameAristotle,
      "Augustin-Jean Fresnel",
      "Christiaan Huygens",
      "Christian Doppler",
      str.nameDemocritus,
      str.nameEpicurus,
      str.nameEuclid,
      "Jacob Christoph Le Blon",
      "James Clerk Maxwell",
      "Johann Wolfgang Goethe",
      "Johannes Kepler",
      "Leon Battista Alberti",
      "Leonardo da Vinci",
      "Max Planck",
      "Niels Bohr",
      str.namePlato,
      str.namePlutarch,
      str.namePtolemy,
      "René Descartes",
      "Robert Grosseteste",
      "Robert Hooke",
      "Roger Bacon",
      "Shinobu Ishihara",
      "Sir Isaac Newton",
      "Sir Henry Enfield Roscoe",
      "Thomas Young",
    ];

    if (currentName !== "") {
      const idx = names.indexOf(currentName);
      if (idx !== -1) {
        names.splice(idx, 1);
      }
    }

    return getRandomElement(names);
  }
}
