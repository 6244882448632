import {
  Box,
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
  Switch,
} from "@material-ui/core";
import CasinoIcon from "@material-ui/icons/Casino";
import React, { Component } from "react";
import { PLAYER_NAME_LENGTH_LIMIT } from "../Config";
import KVDb from "../db/KVDb";
import {
  BackgroundAnimationEntry,
  PlayerNameEntry,
  ThemeEntry,
} from "../db/KVDbEntries";
import Player from "../game/Player";
import str from "../StringResources";
import { finishComponent } from "../Utils";
import { RouteComponentProps } from "react-router";
import AdCardComponent from "./core/AdCardComponent";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import LocalizedStrings from "react-localization";
import { AppTheme, AppThemes } from "../theme/Theme";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import GradientIcon from "@material-ui/icons/Gradient";

const localStr = new LocalizedStrings({
  en: {
    appearance: "Appearance",
    audio: "Audio",
    backgroundAnimation: "Background animation",
    darkMode: "Dark mode",
    game: "Game",
    limitedRandomnessPrimary: "Limited randomness",
    limitedRandomnessSecondary:
      "Specifies whether randomness should be limited to avoid losing only because of bad luck",
    music: "Music",
  },
  pl: {
    appearance: "Wygląd",
    audio: "Dźwięk",
    backgroundAnimation: "Animacja tła",
    darkMode: "Ciemny motyw",
    game: "Gra",
    limitedRandomnessPrimary: "Ograniczona losowość",
    limitedRandomnessSecondary:
      "Określa, czy losowość powinna być ograniczona, aby nie można było przegrać wyłącznie przez pecha",
    music: "Muzyka",
  },
});

interface Props extends RouteComponentProps {
  playerName: string;
  theme: AppTheme;
  isBackgroundAnimationEnabled: boolean;
  musicOn: boolean;
  onNameChange: (name: string) => void;
  onThemeChange: (theme: AppTheme) => void;
  onBackgroundAnimationSwitch: (on: boolean) => void;
  onMusicSwitch: (on: boolean) => void;
}

interface State {
  playerName: string;
  limitedRandomness: boolean;
  theme: AppTheme;
  isBackgroundAnimationEnabled: boolean;
  music: boolean;
}

// TODO: background color setting for animations turned off

export default class SettingsComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      playerName: this.props.playerName,
      limitedRandomness: true,
      theme: this.props.theme,
      isBackgroundAnimationEnabled: this.props.isBackgroundAnimationEnabled,
      music: this.props.musicOn,
    };
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return newState !== this.state;
  }

  render() {
    return (
      <div>
        <Box m={2}>
          <Paper elevation={2}>
            <List subheader={<ListSubheader>{str.player}</ListSubheader>}>
              <ListItem>
                <FormControl fullWidth>
                  <InputLabel htmlFor="player-name">{str.firstName}</InputLabel>
                  <Input
                    id="player-name"
                    autoFocus
                    margin="dense"
                    type="text"
                    value={this.state.playerName}
                    inputProps={{ maxLength: PLAYER_NAME_LENGTH_LIMIT }}
                    onInput={(e) =>
                      this.setState({ playerName: (e.target as any).value })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() =>
                            this.setState({
                              playerName: Player.getRandomName(
                                this.state.playerName
                              ),
                            })
                          }
                        >
                          <CasinoIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    fullWidth
                  />
                </FormControl>
              </ListItem>
              <ListSubheader>{localStr.game}</ListSubheader>
              {this.createSwitchListItem(
                <CasinoIcon />,
                localStr.limitedRandomnessPrimary,
                localStr.limitedRandomnessSecondary,
                this.state.limitedRandomness,
                (on) => {
                  this.setState({
                    limitedRandomness: on,
                  });
                }
              )}
              <ListSubheader>{localStr.appearance}</ListSubheader>
              {this.createSwitchListItem(
                <NightsStayIcon />,
                localStr.darkMode,
                null,
                this.state.theme.isDark,
                (on) => {
                  const theme = on
                    ? AppThemes.FUCHSIA_DARK
                    : AppThemes.FUCHSIA_LIGHT;
                  this.setState({
                    theme: theme,
                  });
                  this.props.onThemeChange(theme);
                }
              )}
              {this.createSwitchListItem(
                <GradientIcon />,
                localStr.backgroundAnimation,
                null,
                this.state.isBackgroundAnimationEnabled,
                (on) => {
                  this.setState({
                    isBackgroundAnimationEnabled: on,
                  });
                  this.props.onBackgroundAnimationSwitch(on);
                }
              )}
              <ListSubheader>{localStr.audio}</ListSubheader>
              {this.createSwitchListItem(
                <MusicNoteIcon />,
                localStr.music,
                null,
                this.state.music,
                (on) => {
                  this.setState({
                    music: on,
                  });
                  this.props.onMusicSwitch(on);
                }
              )}
            </List>

            <Box pt={2}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => this.onSaveAndExit()}
              >
                {str.saveAndExit}
              </Button>
            </Box>
          </Paper>
        </Box>
        <AdCardComponent />
      </div>
    );
  }

  private async save() {
    const db = await KVDb.open();
    db.set(PlayerNameEntry(), this.state.playerName);
    db.set(ThemeEntry(), this.state.theme.id);
    db.set(BackgroundAnimationEntry(), this.state.isBackgroundAnimationEnabled);
    this.props.onNameChange(this.state.playerName);
  }

  private onSaveAndExit() {
    this.save()
      .then((res) => finishComponent(this.props))
      .finally(() => KVDb.close());
  }

  private createSwitchListItem(
    icon: any,
    primaryText: string,
    secondaryText: string | null,
    checked: boolean,
    onSwitch: (on: boolean) => void
  ) {
    return (
      <ListItem>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primaryText} secondary={secondaryText} />
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            onChange={(e, on) => onSwitch(on)}
            checked={checked}
          />
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}
